import { create } from 'zustand';

import { IPhraseWord } from '@/widgets/mnemonic-phrase/confirm-mnemonic-phrase/types';

type IMnemonicPhraseState = {
  currentWord: number;
  fullPhrase: IPhraseWord[];
  phraseEntered: IPhraseWord[];
  phraseLeft: IPhraseWord[];
  cellsFilled: number[];
  inputPhrase: string;
  mnemonicPhrase: string[];
  isDisclaimerRead: boolean;
  setMnemonicPhrase: (phrase: string[]) => void;
  cleanMnemonicPhrase: () => void;
  setCurrentWord: (index: number) => void;
  setFullPhrase: (
    index: number,
    item: string,
    isEntered: boolean,
    id: string,
  ) => void;
  setPhraseEntered: (
    index: number,
    item: string,
    isEntered: boolean,
    id: string,
  ) => void;
  setPhraseLeft: (item: string, isEntered: boolean, id: string) => void;
  deleteWordPhraseEntered: (index: number) => void;
  deleteWordPhraseLeft: (wordId: string) => void;
  setCellsFilled: (number: number) => void;
  deleteCellsFilled: (number: number) => void;
  setIsDisclaimerRead: (is: boolean) => void;
  // deleteInputPhrase: () => void;
  // TODO reword
  segments: Array<string>;
  setSegments: (words: Array<string>) => void;
  deleteSegments: () => void;
  pasteFromKeyboard: () => void;
};

const getEmptySegments = () => Array.from<string>({ length: 12 }).fill('');

export const useMnemonicPhraseStore = create<IMnemonicPhraseState>(
  (set, get) => ({
    currentWord: 0,
    fullPhrase: [],
    phraseEntered: [],
    phraseLeft: [],
    cellsFilled: [],
    inputPhrase: '',
    mnemonicPhrase: [],
    isDisclaimerRead: false,
    setMnemonicPhrase: (phrase) => set(() => ({ mnemonicPhrase: phrase })),
    cleanMnemonicPhrase: () => set(() => ({ mnemonicPhrase: [] })),
    setCurrentWord: (index) => set(() => ({ currentWord: index })),
    setFullPhrase: (
      index: number,
      item: string,
      isEntered: boolean,
      id: string,
    ): void =>
      set((state) => ({
        fullPhrase: [
          ...state.fullPhrase,
          {
            number: index,
            word: item,
            isEntered: isEntered,
            id,
          },
        ],
      })),
    setPhraseEntered: (
      index: number,
      item: string,
      isEntered: boolean,
      id,
    ): void =>
      set((state) => ({
        phraseEntered: [
          ...state.phraseEntered,
          {
            number: index,
            word: item,
            isEntered: isEntered,
            id,
          } as IPhraseWord,
        ],
      })),
    setPhraseLeft: (item: string, isEntered: boolean, id: string): void =>
      set((state) => ({
        phraseLeft: [
          ...state.phraseLeft,
          { word: item, isEntered: isEntered, id } as IPhraseWord,
        ],
      })),
    deleteWordPhraseEntered: (index: number): void => {
      set((state) => ({
        phraseEntered: state.phraseEntered.filter(
          (item) => item.number !== index,
        ),
      }));
    },
    deleteWordPhraseLeft: (wordId: string): void => {
      set((state) => ({
        phraseLeft: state.phraseLeft.filter((item) => item.id !== wordId),
      }));
    },
    setCellsFilled: (number: number): void => {
      set((state) => ({
        cellsFilled: [...state.cellsFilled, number].sort((a, b) => a - b),
      }));
    },
    deleteCellsFilled: (number: number): void => {
      set((state) => ({
        cellsFilled: state.cellsFilled.filter((item) => item !== number),
      }));
    },
    // deleteInputPhrase: (): void => {
    //   set(() => ({
    //     inputPhrase: '',
    //   }));
    // },
    setIsDisclaimerRead: (is) => {
      set(() => ({ isDisclaimerRead: is }));
    },
    // TODO rework
    segments: getEmptySegments(),
    setSegments: (words) => {
      set({
        segments: words,
        inputPhrase: words.map((s) => s.trim()).join(' '),
      });
    },
    deleteSegments: () => {
      set({
        segments: getEmptySegments(),
        inputPhrase: '',
      });
    },
    pasteFromKeyboard: async () => {
      const segments = get().segments;
      const pasted = await navigator.clipboard.readText();
      const wordsArray = pasted.trim().split(' ').slice(0, segments.length);
      let newSegments: Array<string>;

      if (wordsArray.length < 12) {
        newSegments = [
          ...wordsArray,
          ...Array.from<string>({ length: 12 - wordsArray.length }).fill(''),
        ];
      } else {
        newSegments = wordsArray;
      }

      set({
        segments: newSegments,
        inputPhrase: newSegments.map((s) => s.trim()).join(' '),
      });
    },
  }),
);
