type ScriptOptions = {
  src: string;
  id: string;
  async: boolean;
};

const createElement: typeof document.createElement = (tag: string) =>
  document.createElement(tag);

const loadScript = (options: ScriptOptions): Promise<boolean> => {
  return new Promise((resolve) => {
    const elScript = createElement('script');

    elScript.id = options.id;
    elScript.async = options.async;
    elScript.src = options.src;
    elScript.addEventListener('load', () => resolve(true));

    document.body.appendChild(elScript);
  });
};

export { loadScript, createElement };
