import { isExported } from '@/shared/constants/constants';
import {
  EncryptionType,
  PublicKey,
} from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import {
  Key,
  Permission,
} from '@/shared/lib/secure-json/core/secure-json-collection/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';

const exportedModeWriteErrorEvent = (collection: CollectionName) =>
  new CustomEvent('ExportedModeWriteErrorEvent', {
    detail: {
      collection,
    },
  });

export class ExportedModeWriteError extends Error {
  constructor(message: string, collection: CollectionName) {
    super(message);
    this.name = 'ExportedModeWriteError';
    self?.dispatchEvent?.(exportedModeWriteErrorEvent(collection));
  }
}
export class SJCollection<T extends BaseDto> {
  private protectedKeys = [CollectionName.MIGRATIONS];

  private throwIfProtectedKey(): void {
    if (this.protectedKeys.includes(this.collectionName)) {
      throw new Error(`Collection ${this.collectionName} is protected`);
    }
  }

  private throwIfExportedMode(): void {
    if (isExported) {
      throw new ExportedModeWriteError(
        `Collection ${this.collectionName} is protected in exported mode`,
        this.collectionName,
      );
    }
  }

  constructor(
    private readonly collectionName: CollectionName,
    private readonly secureJsonBase: SecureJsonBase,
    private readonly setSecureJsonBase: (
      name: CollectionName,
      secureJsonBase: SecureJsonBase,
    ) => void,
  ) {
    // log.trace('SJCollection constructor', this.protectedKeys);
  }

  size(): number {
    return this.secureJsonBase.items.size;
  }

  async create(
    value: T,
    publicKeys: Array<PublicKey>,
    permissions?: Map<PublicKey, Set<Permission>>,
  ): Promise<T> {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    value.createdAt = new Date();
    value.updatedAt = new Date();
    await this.secureJsonBase.set({
      key: value.id,
      data: value,
      publicKeys,
      permissions,
    });
    this.commitCollection();
    return value;
  }

  // TODO unused
  // createMany(values: T[], publicKeys: Array<PublicKey>): T[] {
  //   for (const value of values) this.create(value, publicKeys);
  //   this.commitCollection();
  //   return values;
  // }

  async update(value: T): Promise<T> {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    value.updatedAt = new Date();
    await this.secureJsonBase.set({
      key: value.id,
      data: value,
    });
    this.commitCollection();
    return value;
  }

  async updateMany(values: T[]): Promise<T[]> {
    for await (const value of values) {
      await this.update(value);
    }

    this.commitCollection();
    return values;
  }

  findOne(
    condition: (item: T) => boolean,
    sort?: (a: T, b: T) => number,
    skip?: number,
    limit?: number,
  ): T | undefined {
    let collection = this.secureJsonBase
      .values()
      .filter((value) => value.decryptedData);

    if (sort) {
      collection = collection.sort((value1, value2) =>
        sort(value1.decryptedData, value2.decryptedData),
      );
    }

    if (skip) {
      collection = collection.slice(skip, collection.length);
    }

    if (limit) {
      collection = collection.slice(0, limit);
    }

    const result = collection.find((value) => condition(value.decryptedData));

    return result?.decryptedData;
  }

  findMany(
    condition: (item: T) => boolean,
    sort?: (a: T, b: T) => number,
    skip?: number,
    limit?: number,
  ): T[] {
    let collection = this.secureJsonBase
      .values()
      .filter((value) => value.decryptedData);

    if (sort) {
      collection = collection.sort((value1, value2) =>
        sort(value1.decryptedData, value2.decryptedData),
      );
    }

    if (skip) {
      collection = collection.slice(skip, collection.length);
    }

    if (limit) {
      collection = collection.slice(0, limit);
    }

    return collection
      .filter((value) => condition(value.decryptedData))
      .map((value) => value.decryptedData);
  }

  remove(value: T): T {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    this.secureJsonBase.delete(value.id);
    this.commitCollection();
    return value;
  }

  removeMany(condition: (item: T) => boolean): T[] {
    const values = this.findMany(condition);

    for (const value of values) this.remove(value);

    this.commitCollection();
    return values;
  }

  removeAll(): Key[] {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    const keys = this.secureJsonBase.keys();

    for (const key of keys) {
      if (key) this.secureJsonBase.delete(key);
    }

    this.commitCollection();
    return keys;
  }

  // Public keys
  public getPublicKeys(key: Key): PublicKey[] | undefined {
    return this.secureJsonBase.getPublicKeys(key);
  }

  public async putPublicKey(
    id: Key,
    publicKey: PublicKey,
    encryptionType?: EncryptionType,
  ): Promise<boolean> {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    await this.secureJsonBase.putPublicKey(id, publicKey, encryptionType);
    this.commitCollection();
    return true;
  }

  public deletePublicKey(id: Key, publicKey: PublicKey): void {
    this.secureJsonBase.deletePublicKey(id, publicKey);
    this.commitCollection();
  }

  // Permissions

  public getPermissions(key: Key): Map<PublicKey, Set<Permission>> | undefined {
    return this.secureJsonBase.getPermissions(key);
  }

  public putPermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    this.secureJsonBase.putPermission(key, publicKey, permission);
    this.commitCollection();
  }

  public deletePermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    this.secureJsonBase.deletePermission(key, publicKey, permission);
    this.commitCollection();
  }

  // Commit collection

  private commitCollection(): void {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    this.setSecureJsonBase(this.collectionName, this.secureJsonBase);
  }
}
