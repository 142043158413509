/*
	https://developer.zendesk.com/api-reference/widget-messaging/web/core/
*/
import { create } from 'zustand';

import { zapi } from '../helpers';

type ZendeskApiState = 'none' | 'loading' | 'ready' | 'error';

export type ZendeskStore = {
  state: ZendeskApiState;
  isOpened: boolean;
  hasUnread: boolean;
  // initApi: (token: string, openAfterInit?: boolean) => void;
  setState: (state: ZendeskApiState) => void;
  setIsOpened: (isOpened: boolean) => void;
  setUnread: (count: number) => void;
  toggle: () => void;

  //
  shouldOpenSupport: boolean;
  setShouldOpenSupport: (value: boolean) => void;
};

export const useZendeskStore = create<ZendeskStore>((set, get) => ({
  state: 'none',
  isOpened: false,
  hasUnread: false,
  setIsOpened: (isOpened) => set({ isOpened }),
  setState: (state: ZendeskApiState) => set({ state }),
  setUnread: (count) => set({ hasUnread: count > 0 }),
  toggle: () => {
    const opened = get().isOpened;

    if (opened) {
      zapi('messenger', 'close');
    } else {
      zapi('messenger', 'open');
    }
  },
  //
  shouldOpenSupport: false,
  setShouldOpenSupport: (shouldOpenSupport) => set({ shouldOpenSupport }),
}));
