import { FC } from 'react';
import { useRouter } from 'next/router';
import { Tag, Typography } from 'antd';
import styled from 'styled-components';

import { useScenarios } from '@/entities/scenario';
import { ScenariosQuery, ScenarioStatus } from '@/shared/generated/graphql';
const { Text } = Typography;

type ProfileScenariosProps = {
  scenarios: ScenariosQuery['scenarios'];
};

export const ProfileScenarios: FC<ProfileScenariosProps> = ({ scenarios }) => {
  const { getScenarioName } = useScenarios();
  const { push } = useRouter();

  return (
    <Wrapper>
      <Text>Algorithms:</Text>

      {scenarios && scenarios.length > 0 ? (
        scenarios.map((scenario) => {
          const color =
            scenario.status === ScenarioStatus.Done
              ? '#7bf70026'
              : scenario.status === ScenarioStatus.Idle
              ? '#FEEDD9'
              : '#D0D4DC';

          const redirectToScenarioPage = () => {
            push(
              { pathname: `/scenarios/scenario/${scenario.id}` },
              undefined,
              { shallow: true },
            );
          };

          return (
            <StyledTag
              key={scenario.id}
              color={color}
              onClick={redirectToScenarioPage}
            >
              <AlgoIcon />
              {getScenarioName(scenario.id)?.name}
            </StyledTag>
          );
        })
      ) : (
        <Typography.Text>None</Typography.Text>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  text-wrap: nowrap;
`;

const StyledTag = styled(Tag)`
  &&& {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #060136;
    font-family: 'Avenir Next Cyr';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    margin-right: 0;
  }
`;

const AlgoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M7.5 5.25H9.75C9.94891 5.25 10.1397 5.17098 10.2803 5.03033C10.421 4.88968 10.5 4.69891 10.5 4.5V2.25C10.5 2.05109 10.421 1.86032 10.2803 1.71967C10.1397 1.57902 9.94891 1.5 9.75 1.5H7.5C7.30109 1.5 7.11032 1.57902 6.96967 1.71967C6.82902 1.86032 6.75 2.05109 6.75 2.25V3H6C5.70163 3 5.41548 3.11853 5.2045 3.3295C4.99353 3.54048 4.875 3.82663 4.875 4.125V5.625H3.375V5.25C3.375 5.05109 3.29598 4.86032 3.15533 4.71967C3.01468 4.57902 2.82391 4.5 2.625 4.5H1.125C0.926088 4.5 0.735322 4.57902 0.59467 4.71967C0.454018 4.86032 0.375 5.05109 0.375 5.25V6.75C0.375 6.94891 0.454018 7.13968 0.59467 7.28033C0.735322 7.42098 0.926088 7.5 1.125 7.5H2.625C2.82391 7.5 3.01468 7.42098 3.15533 7.28033C3.29598 7.13968 3.375 6.94891 3.375 6.75V6.375H4.875V7.875C4.875 8.17337 4.99353 8.45952 5.2045 8.6705C5.41548 8.88147 5.70163 9 6 9H6.75V9.75C6.75 9.94891 6.82902 10.1397 6.96967 10.2803C7.11032 10.421 7.30109 10.5 7.5 10.5H9.75C9.94891 10.5 10.1397 10.421 10.2803 10.2803C10.421 10.1397 10.5 9.94891 10.5 9.75V7.5C10.5 7.30109 10.421 7.11032 10.2803 6.96967C10.1397 6.82902 9.94891 6.75 9.75 6.75H7.5C7.30109 6.75 7.11032 6.82902 6.96967 6.96967C6.82902 7.11032 6.75 7.30109 6.75 7.5V8.25H6C5.90054 8.25 5.80516 8.21049 5.73484 8.14017C5.66451 8.06984 5.625 7.97446 5.625 7.875V4.125C5.625 4.02554 5.66451 3.93016 5.73484 3.85984C5.80516 3.78951 5.90054 3.75 6 3.75H6.75V4.5C6.75 4.69891 6.82902 4.88968 6.96967 5.03033C7.11032 5.17098 7.30109 5.25 7.5 5.25ZM2.625 6.75H1.125V5.25H2.625V6.75ZM7.5 7.5H9.75V9.75H7.5V7.5ZM7.5 2.25H9.75V4.5H7.5V2.25Z"
      fill="#000430"
    />
  </svg>
);
