import { COLORS } from '../constants/colors';

const locale = 'en-GB';

export const dates = {
  formatShort: (date: Date) => {
    return date
      ?.toLocaleDateString(locale, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      .replace(/ /g, ' ');
  },

  formatMask: (date: Date, options?: Intl.DateTimeFormatOptions) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new TypeError('Invalid date');
    }

    return new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      ...options,
    }).format(date);
  },

  getLocalTimeWithTimezone: (
    date: Date,
    options?: Intl.DateTimeFormatOptions,
  ) => {
    const formatter = new Intl.DateTimeFormat(
      locale,
      options ?? {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      },
    );

    return formatter.format(date);
  },

  remindDaysColors: (triggerValue: number) => {
    const currentDate = new Date();
    const targetDate = new Date(triggerValue);

    const targetDayStart = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate(),
    );

    const diffInMs = targetDayStart.getTime() - currentDate.getTime();
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    let text = '';
    let color = '';

    if (diffInDays > 30) {
      text = `${diffInDays} days`;
      color = COLORS.colorTextBase;
    } else if (diffInDays > 1 && diffInDays < 30) {
      text = `${diffInDays} days`;
      color = COLORS.warning;
    } else if (diffInDays === 1) {
      text = `1 (tomorrow)`;
      color = COLORS.danger;
    } else if (diffInDays === 0) {
      if (targetDate.getTime() > currentDate.getTime()) {
        text = '0 (today)';
        color = COLORS.danger;
      } else {
        text = '0 (today)';
        color = COLORS.success;
      }
    } else if (diffInDays === -1) {
      text = `1 (yesterday)`;
      color = COLORS.success;
    } else {
      text = `${Math.abs(diffInDays)} days`;
      color = COLORS.success;
    }

    return {
      text,
      color,
    };
  },

  remindDays: (triggerValue: number) => {
    const now = Date.now();
    const timeDifference = triggerValue - now;
    const daysRemaining = Math.round(timeDifference / (1000 * 60 * 60 * 24));
    let textColor;

    if (daysRemaining < 0) {
      if (timeDifference > 0) {
        textColor = COLORS.danger;
      } else {
        textColor = COLORS.success;
      }
    } else if (daysRemaining < 1) {
      textColor = COLORS.danger;
    } else if (daysRemaining <= 30) {
      textColor = COLORS.warning;
    } else {
      textColor = 'inherit';
    }

    return {
      daysRemaining,
      metric: Math.abs(daysRemaining) > 1 ? 'days' : 'day',
      color: textColor,
    };
  },
};
