import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Account } from '@/shared/generated/graphql';
import { DeepPartial } from '@/shared/types/utility-types';

type IAuthState = {
  token: string | null;
  account: DeepPartial<Account> | null;
  isBeneficiary: boolean;
  setToken: (token: string | undefined) => void;
  setAccount: (account: DeepPartial<Account> | undefined) => void;
  setIsBeneficiary: (is: boolean) => void;
  clear: () => void;
};

export const useAuthStore = create(
  persist<IAuthState>(
    (set) => ({
      token: null,
      account: null,
      profile: null,
      isBeneficiary: false,
      setToken: (token) => set({ token }),
      setAccount: (account) => set({ account }),
      setIsBeneficiary: (isBeneficiary) => set({ isBeneficiary }),
      clear: () =>
        set({
          token: undefined,
          account: undefined,
          isBeneficiary: false,
        }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
