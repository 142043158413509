import React, {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FC,
  memo,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Control,
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { PlusOutlined } from '@ant-design/icons';
import {
  Divider,
  Form,
  Input as AntInput,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { nanoid } from 'nanoid';
import { FilterFunc } from 'rc-select/es/Select';
import { CurrenciesSelect } from 'src/shared/components/currencies-select';
import { Tooltip } from 'src/shared/components/tooltip';
import { DelegateButton } from 'src/widgets/delegate-button';
import styled from 'styled-components';

import { useFileEncryptionUpload } from '@/entities/file/hooks/use-file-encryption-upload';
import { checkCondition2 } from '@/shared/_legacy_/condition-utils';
import { Button, Dropdown, Input } from '@/shared/components';
import { DrawerPickArrayOfDto } from '@/shared/components/drawers';
import { Container, Row } from '@/shared/components/layout';
import {
  PhoneCodesListForm,
  resolveCountry,
} from '@/shared/constants/countries-list';
import { useCurrencies } from '@/shared/hooks/use-currencies';
import {
  FormItemLabelType,
  FormLabelItem,
  isLabels,
  TDtoWrapper,
  ValueType,
} from '@/shared/lib/forms/form-wrapper.types';
import { toDto } from '@/shared/lib/hooks/use-form-render/format-functions';
import { UseFormRenderInputType } from '@/shared/lib/hooks/use-form-render/types';
import {
  separator,
  TextAreaDefaultRowsCount,
} from '@/shared/lib/hooks/use-form-render-old/constants';
import {
  DefaultInputToInputType,
  isDefaultInput,
} from '@/shared/lib/hooks/use-form-render-old/types';
import {
  CollectionName,
  CollectionsTypeValueMap,
  ICollections,
} from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';
import { ArrayOrOne } from '@/shared/types/utility-types';
import { log } from '@/shared/utils/log';
import { isEmpty } from '@/shared/utils/misc';

type ValidateErrorEntity = {
  errorFields: {
    name: (string | number)[];
    errors: string[];
  }[];
};

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export const useFormRender = <
  TDto extends Omit<BaseDto, 'id'>,
  THelp extends { [TKey in keyof TDto]: QuestionType },
>({
  form,
  texts = {},
  onSubmit: onExternalSubmit,
  onDelegationSubmit: onExternalDelegationSubmit,
  defaultValues = {},
  // removeAllRequired,
  isLastStep,
  withDelegation = false,
}: UseFormRenderInputType<TDto, THelp>) => {
  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const { handleSubmit, control, watch, setError, getValues, setValue } =
    formMethods;

  const isNewInited = useRef(false);

  const _form = new Proxy(form, {
    set(item, prop, value) {
      // eslint-disable-next-line security/detect-object-injection,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      item[prop] = value;
      setForceRerender((prev) => ({ ...prev, ...{} }));
      return true;
    },
  });

  const memoizedValue = useRef<Record<string, unknown>>(defaultValues);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (
      (defaultValues as { 'add-new'?: Record<string, unknown> })['add-new'] &&
      !isNewInited.current
    ) {
      isNewInited.current = true;

      for (const [key, item] of Object.entries(defaultValues['add-new'])) {
        for (const [_key, it] of Object.entries(
          item as Record<string, unknown>,
        )) {
          for (const [k, v] of Object.entries(it as Record<string, unknown>)) {
            if (!isEmpty(v)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              _form[
                `add-new${separator}${key}${separator}${k}${separator}${_key}`
              ] =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                _form[k];
              memoizedValue.current[
                `add-new${separator}${key}${separator}${k}${separator}${_key}`
              ] = v;
            }
          }
        }
      }
    }
  }, [defaultValues, isNewInited.current]);

  useEffect(() => {
    for (const i of Object.keys(form)) {
      if (i.startsWith('add-new') && isEmpty(memoizedValue.current[i], true)) {
        delete form[i as keyof typeof form];
        delete memoizedValue.current[i];
      }
    }
  }, [form]);

  const [disabled, setDisabled] = useState<boolean>(false);

  const entityType = watch(defaultValues).entityType;

  useEffect(() => {
    if (entityType === 'passive') {
      setDisabled(true);
    } else setDisabled(false);
  }, [entityType]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (isLastStep === undefined || isLastStep) {
      setDisabled(true);
    }
    memoizedValue.current = { ...memoizedValue.current, ...toDto(data) };
    onExternalSubmit?.(toDto(data));
  };

  const onDelegationSubmit: SubmitHandler<FieldValues> = (data) => {
    if (isLastStep === undefined || isLastStep) {
      setDisabled(true);
    }
    memoizedValue.current = { ...memoizedValue.current, ...toDto(data) };
    onExternalDelegationSubmit?.(toDto(data));
  };

  const [forceReRender, setForceRerender] =
    useState<Record<string, unknown>>(defaultValues);

  useEffect(() => {
    // eslint-disable-next-line no-void
    return () => void (memoizedValue.current = {});
  }, []);

  useEffect(() => {
    let subscription: Subscription;
    const watchingItems = Object.entries(_form)
      .concat(Object.entries(texts))
      .filter((question) => question[1].showIf)
      .map((question) => Object.keys(question[1].showIf)[0]);
    if (watchingItems.length) {
      subscription = watch((data) => {
        const result = {};
        let shouldRerender = false;
        for (const item of watchingItems) {
          const res = data[item]?.key ? data[item].key : data[item];
          Object.assign(result, {
            [item]: res,
          });
          if (res !== forceReRender[item]) shouldRerender = true;
        }
        if (shouldRerender) {
          setForceRerender(result);
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, [forceReRender, _form]);

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    log.error('Failed submit:', errorInfo);

    for (const error of errorInfo.errorFields) {
      const requiredField = error.errors.find((e) =>
        e.startsWith('Please enter'),
      );
      if (requiredField) {
        const index = error.errors.indexOf(requiredField);
        if (index >= 0) {
          setError(String(error.name[index]), {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            message: 'Required field',
          });
        }
      }
    }
  };

  const { t } = useTranslation([
    'common',
    'assets',
    'forms_assets',
    'forms_contacts',
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const component = useMemo(() => {
    const formItems =
      Object.entries<ValueType<QuestionType, typeof form>>(_form);

    const _texts = Object.entries(texts);

    const items = [...formItems, ..._texts].sort((a, b) =>
      !(typeof a[1]?.order === 'number' && !b[1]?.order)
        ? Number(a[1]?.order) - Number(b[1]?.order)
        : -100,
    );

    return (
      <FormProvider {...formMethods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          onFinishFailed={onFinishFailed}
          name={'basic'}
        >
          <Row nowrap direction="column" gapRow={16}>
            {items.map(([dtoKey, item]) => {
              if (!isLabels(item.type)) {
                const _item = item as ValueType<
                  QuestionType,
                  TDtoWrapper<TDto, THelp>
                >;
                if (_item.showIf) {
                  //todo
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  return checkCondition2(forceReRender, _item.showIf)
                    ? renderQuestion(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        _item,
                        control,
                        dtoKey,
                        t,
                        memoizedValue,
                        _form,
                        setValue,
                      )
                    : null;
                }
                return renderQuestion(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  _item,
                  control,
                  dtoKey,
                  t,
                  memoizedValue,
                  _form,
                  setValue,
                );
              }
              const _item = item as FormItemLabelType<unknown>;
              if (_item.showIf) {
                return checkCondition2(forceReRender, _item.showIf)
                  ? renderText(
                      item as FormItemLabelType<unknown>,
                      dtoKey,
                      t,
                      memoizedValue,
                    )
                  : null;
              }
              return renderText(
                item as FormItemLabelType<unknown>,
                dtoKey,
                t,
                memoizedValue,
              );
            })}
          </Row>
          <Divider />
          <Button
            text={
              isLastStep === undefined || isLastStep
                ? t('common:submit')
                : t('common:continue')
            }
            type="primary"
            htmlType="submit"
            disabled={disabled}
          />
          {withDelegation ? (
            <Container marginTop={24}>
              <DelegateButton
                onClick={handleSubmit(onDelegationSubmit)}
                withoutCreation
              />
            </Container>
          ) : null}
        </Form>
      </FormProvider>
    );
  }, [_form, forceReRender]);

  return useMemo(
    () => ({ component, setFormValue: setValue, getValues }),
    [component, setValue, getValues],
  );
};

const renderText = (
  data: FormItemLabelType<unknown>,
  key: string,
  t: (key: string) => string,
  memoizedValue: MutableRefObject<Record<string, unknown>>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const { label: _label } = data;
  let label = '';

  if (typeof _label === 'string') {
    label = t(_label);
  } else if (typeof _label === 'object') {
    label = '';

    const path = _label.fieldKey.split('.');

    if (path.length > 2) {
      label = '';
      console.warn(
        '[useFormRender WARN]: Label fieldKey depth max 2 available',
      );
    } else if (path.length === 2) {
      const value = memoizedValue.current[path[0]] as Record<string, unknown>;
      const searchingValue = value?.[path[1]];

      if (searchingValue) {
        label = `${t(_label.prefix)} ${
          _label.formator
            ? _label.formator(String(searchingValue))
            : searchingValue
        } ${t(_label.postfix)}`;
      } else {
        label = '';
      }
    } else {
      label = `${t(_label.prefix)} ${memoizedValue.current[path[0]]} ${t(
        _label.postfix,
      )}`;
    }
  }

  if (data.type === FormLabelItem.h1) {
    return (
      <div style={{ display: 'flex' }}>
        <Typography.Title level={1} key={key}>
          {label}
        </Typography.Title>
        {data.tooltip && <Tooltip title={t(data.tooltip)} />}
      </div>
    );
  }
  if (data.type === FormLabelItem.text) {
    return (
      <div style={{ display: 'flex' }}>
        <Typography.Text key={key}>{label}</Typography.Text>
        {data.tooltip && <Tooltip title={t(data.tooltip)} />}
      </div>
    );
  }
  if (data.type === FormLabelItem.label) {
    return (
      <div style={{ display: 'flex' }}>
        <Typography.Title level={5} key={key}>
          {label}
        </Typography.Title>
        {data.tooltip && <Tooltip title={t(data.tooltip)} />}
      </div>
    );
  }

  log.error('Unknown text type!', data);

  return null;
};

const getRequiredRule = (question: ValueType<QuestionType, unknown>) =>
  question.required ? 'Field is required' : false;

// eslint-disable-next-line complexity
const renderQuestion = (
  question: ValueType<QuestionType, unknown>,
  control: Control,
  id: string,
  t: (key: string) => string,
  memoizedValue: MutableRefObject<Record<string, unknown>>,
  form: Record<string, unknown>,
  setValue: UseFormSetValue<Record<string, unknown>>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ReactNode => {
  const name = (postfix = '') =>
    postfix ? `${id}${separator}${postfix}` : `${id}`;

  if (question.type === QuestionType.PHONE_NUMBER) {
    const { label: _label } = question;
    const label =
      typeof _label === 'string'
        ? t(_label)
        : `${t(_label.prefix)}${
            _label.fieldKey ? ` ${memoizedValue.current[_label.fieldKey]} ` : ''
          }${t(_label.postfix)}`;
    const memoData =
      typeof memoizedValue.current[id] === 'string'
        ? (memoizedValue.current[id] as string)?.split(' ')
        : undefined;
    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <PhoneNumberWrapper>
          <Controller
            name={name('Dropdown')}
            control={control}
            rules={{ required: getRequiredRule(question) }}
            render={({ field, fieldState }) => {
              return (
                <Form.Item
                  validateStatus={fieldState.error ? 'error' : ''}
                  help={fieldState.error?.message}
                >
                  <DropdownPhoneNumber
                    showSearch
                    placeholder={t('common:phoneCode')}
                    options={PhoneCodesListForm}
                    defaultValue={memoData?.[0]}
                    {...field}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            name={name('Input')}
            control={control}
            rules={{ required: getRequiredRule(question) }}
            render={({ field, fieldState }) => {
              return (
                <Form.Item
                  validateStatus={fieldState.error ? 'error' : ''}
                  help={fieldState.error?.message}
                >
                  <Input
                    placeholder={label}
                    type={'number'}
                    defaultValue={memoData?.[1]}
                    {...field}
                  />
                </Form.Item>
              );
            }}
          />
        </PhoneNumberWrapper>
      </div>
    );
  }

  if (question.type === QuestionType.TIME_DURATION) {
    const { label: _label } = question;
    const label =
      typeof _label === 'string'
        ? t(_label)
        : `${t(_label.prefix)}${
            _label.fieldKey ? ` ${memoizedValue.current[_label.fieldKey]} ` : ''
          }${t(_label.postfix)}`;
    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Controller
          name={name('Dropdown')}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Dropdown
                  placeHolder={label}
                  items={question.items}
                  defaultValue={memoizedValue.current[id] as string}
                  {...field}
                />
              </Form.Item>
            );
          }}
        />
        <Controller
          name={name('Input')}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Input placeholder={label} type={'number'} {...field} />
              </Form.Item>
            );
          }}
        />
      </div>
    );
  }
  if (question.type === QuestionType.CURRENCY_AMOUNT) {
    const { label: _label } = question;
    const label =
      typeof _label === 'string'
        ? t(_label)
        : `${t(_label.prefix)}${
            _label.fieldKey ? ` ${memoizedValue.current[_label.fieldKey]} ` : ''
          }${t(_label.postfix)}`;
    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Space size={'large'}>
          <Controller
            name={name('Dropdown')}
            control={control}
            rules={{ required: getRequiredRule(question) }}
            render={({ field, fieldState }) => {
              return (
                <Form.Item
                  validateStatus={fieldState.error ? 'error' : ''}
                  help={fieldState.error?.message}
                >
                  <Dropdown
                    placeHolder={label}
                    items={question.items}
                    defaultValue={memoizedValue.current[id] as string}
                    {...field}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            name={name('Input')}
            control={control}
            rules={{ required: getRequiredRule(question) }}
            render={({ field, fieldState }) => {
              return (
                <Form.Item
                  validateStatus={fieldState.error ? 'error' : ''}
                  help={fieldState.error?.message}
                >
                  <Input placeholder={label} type={'number'} {...field} />
                </Form.Item>
              );
            }}
          />
        </Space>
      </div>
    );
  }

  const { label: _label } = question;
  const label =
    typeof _label === 'string'
      ? t(_label)
      : `${t(_label.prefix)}${
          _label.fieldKey ? ` ${memoizedValue.current[_label.fieldKey]} ` : ''
        }${t(_label.postfix)}`;

  if (question.type === QuestionType.SELECT_FROM_LIST) {
    const items = question.items;
    const { withSearch } = question;
    let result: {
      label: React.ReactNode;
      value?: string | number | null;
    }[] = [];
    if (Array.isArray(items)) {
      result = items.map((it) => {
        const ifCountry = resolveCountry(it.key, false);
        return {
          value: it.key,
          label: ifCountry ? ifCountry : t(it.label),
        };
      });
    }
    const countriesFilterOption: FilterFunc<{
      label: React.ReactNode;
      value?: string | number | null | undefined;
    }> = (inputValue, option) => {
      if (React.isValidElement(option?.label)) {
        const labelText = String(
          option?.label.props.children[1].props.children,
        );
        return labelText.toLowerCase().includes(inputValue.toLowerCase());
      }
      return false;
    };

    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Controller
          name={name()}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Select
                  showSearch={withSearch}
                  filterOption={countriesFilterOption}
                  placeholder={label}
                  options={result}
                  style={{ width: '100%' }}
                  mode={question.mode}
                  defaultValue={memoizedValue.current[id] as string}
                  {...field}
                />
              </Form.Item>
            );
          }}
        />
      </div>
    );
  }

  if (question.type === QuestionType.FILE_UPLOAD) {
    return (
      <FileUploadingComponent
        question={question}
        label={label}
        t={t}
        name={name()}
        id={id}
        key={id}
        control={control}
        memoizedValue={memoizedValue}
        setValue={setValue}
      />
    );
  }

  if (isDefaultInput(question.type)) {
    const inputType = DefaultInputToInputType[question.type];

    return (
      <InputComponent
        inputType={inputType}
        control={control}
        name={name()}
        question={question}
        label={label}
        placeholder={question.placeholder || label}
        t={t}
        setValue={setValue}
        id={id}
        key={id}
        memoizedValue={memoizedValue}
        deps={question.deps as Array<string>}
        collection={question.collection}
      />
    );
  }

  if (question.type === QuestionType.DATE_TIME) {
    return (
      <div key={id}>
        <span>{t(label)}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Controller
          name={name()}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => (
            <Form.Item
              validateStatus={fieldState.error ? 'error' : ''}
              help={fieldState.error?.message}
            >
              <Input type={'date'} placeholder={label} {...field} />
            </Form.Item>
          )}
        />
      </div>
    );
  }

  if (question.type === QuestionType.TEXT_MULTILINE) {
    const { TextArea } = AntInput;
    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Controller
          name={name()}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <TextArea
                  placeholder={label}
                  rows={TextAreaDefaultRowsCount}
                  {...field}
                />
              </Form.Item>
            );
          }}
        />
      </div>
    );
  }

  if (question.type === QuestionType.CHECKBOX) {
    return (
      <Row key={id} justifyContent={'space-between'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {label} {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        </span>

        <Controller
          name={name()}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Switch checked={field.value as boolean} {...field} />
              </Form.Item>
            );
          }}
        />
      </Row>
    );
  }

  if (question.type === QuestionType.RADIO_BUTTON) {
    return (
      <div key={id}>
        <span>{label}</span>
        {question.tooltip && <Tooltip title={t(question.tooltip)} />}
        <Controller
          name={name()}
          control={control}
          rules={{ required: getRequiredRule(question) }}
          render={({ field, fieldState }) => {
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
                valuePropName="checked"
              >
                <Radio
                  checked={memoizedValue.current[id] as boolean}
                  {...field}
                />
              </Form.Item>
            );
          }}
        />
      </div>
    );
  }

  if (question.type === QuestionType.PICK_ARRAY_OF_DTO) {
    return (
      <PickArrayOfDto
        label={label}
        required={question.required}
        control={control}
        collection={question.items}
        name={name()}
        mode={question.mode}
        tooltip={question.tooltip}
        memoValue={memoizedValue}
        setValue={setValue}
        filter={question.filter}
        deps={question.deps as Array<string>}
        type={question.dtoType}
        key={id}
      />
    );
  }

  if (question.type === QuestionType.CURRENCY_CALCULATOR) {
    return (
      <CurrenciesCalculator
        label={label}
        name={name()}
        control={control}
        required={question.required}
        tooltip={question.tooltip}
        equivalentValueTooltip={question.equivalentValueTooltip}
        id={id}
        key={id}
        memoValue={memoizedValue}
      />
    );
  }

  if (question.type === QuestionType.RENDER_NEW_BUTTON) {
    return (
      <AddNew
        question={question}
        control={control}
        memoValue={memoizedValue}
        form={form}
        tooltip={question.tooltip}
        key={id}
        id={id}
      />
    );
  }

  log.error('Unknown question type!', question);

  return null;
};

const CurrenciesCalculator = ({
  label,
  required,
  control,
  name,
  tooltip,
  equivalentValueTooltip,
  memoValue,
}: {
  label: string;
  required?: boolean;
  control: Control;
  name: string;
  tooltip?: string;
  equivalentValueTooltip?: string;
  id: string;
  memoValue: MutableRefObject<Record<string, unknown>>;
}) => {
  const { update, fields } = useFieldArray({ name: name, control });
  const { calculateCurrency, list } = useCurrencies();
  const sortedList = [...list].sort((a, b) =>
    a.currencyCode.localeCompare(b.currencyCode),
  );
  const { t } = useTranslation(['common']);
  const [selectedCurrency, setSelectedCurrency] = useState(
    list[0]?.baseCurrency || 'USD',
  );

  useEffect(() => {
    if (!memoValue.current[name]) {
      memoValue.current[name] = {};
    } else if ((memoValue.current[name] as { from: string }).from) {
      setSelectedCurrency((memoValue.current[name] as { from: string }).from);
    }
  }, [memoValue.current[name]]);

  const onCurrencyChose = (e: string) => {
    (memoValue.current[name] as { from: string }).from = e;
    setSelectedCurrency(e);
    update(0, {
      [name]: {
        calculatedValue: calculateCurrency({
          from: e,
          amount: +(memoValue.current[name] as { value: string }).value,
        }).value.toFixed(2),
        from: e,
        value: +(memoValue.current[name] as { value: string }).value,
      },
    });
  };

  return (
    <div>
      <Row nowrap alignCenter justifyContent="space-between"></Row>
      <div style={{ display: 'flex' }}>
        <p>{label}</p>
        {tooltip && <Tooltip title={t(tooltip)} />}
      </div>
      <PhoneNumberWrapper>
        <CurrenciesSelect
          showSearch={true}
          placeholder={'USD'}
          options={sortedList.map((item) => ({
            key: item.currencyCode,
            label: item.currencyCode,
            value: item.currencyCode,
          }))}
          onChange={onCurrencyChose}
          defaultValue={(memoValue.current[name] as { from: string })?.from}
          dataCy="currencyDropdown"
        />
        <Controller
          name={`${name}-cost-nominal`}
          control={control}
          data-testid={'amountInput'}
          rules={{ required }}
          defaultValue={(memoValue.current[name] as { value: string })?.value}
          render={({ field, fieldState }) => {
            const { onChange: fieldOnChange, ...restField } = field;
            return (
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Input
                  placeholder={label}
                  type={'number'}
                  onChange={(e: { target: { value: string } }) => {
                    update(0, {
                      [name]: {
                        calculatedValue: calculateCurrency({
                          from: selectedCurrency,
                          amount: +e.target.value,
                        }).value.toFixed(2),
                        from: selectedCurrency,
                        value: +e.target.value,
                      },
                    });
                    (memoValue.current[name] as { value: string }).value =
                      e.target.value;
                    (memoValue.current[name] as { from: string }).from =
                      selectedCurrency;
                    fieldOnChange(e);
                  }}
                  defaultValue={
                    (memoValue.current[name] as { value: string })?.value
                  }
                  {...restField}
                  onKeyDown={(evt: {
                    key: string;
                    preventDefault: () => void;
                  }) =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  value={(memoValue.current[name] as { value: string })?.value}
                />
              </Form.Item>
            );
          }}
        />
      </PhoneNumberWrapper>
      <Row marginTop={4} direction={'column'}>
        <div style={{ display: 'flex' }}>
          <p>{t('forms_assets:common.inputs.equivalentValue.label')}</p>
          {equivalentValueTooltip && (
            <Tooltip title={t(equivalentValueTooltip)} />
          )}
        </div>

        <input
          style={{
            padding: '10px',
            borderRadius: '6px',
            border: '1px solid #e0dac2',
          }}
          placeholder={''}
          id={fields[0]?.id}
          value={
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            (isNaN(fields[0]?.[name].calculatedValue)
              ? 0
              : //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                fields[0]?.[name].calculatedValue) ||
            (memoValue.current?.[name] as { calculatedValue?: string })
              ?.calculatedValue
          }
          disabled
        />
      </Row>
    </div>
  );
};

export const PickArrayOfDto = <
  K extends CollectionName,
  T extends CollectionsTypeValueMap[K],
>({
  label,
  required,
  control,
  collection,
  name,
  filter = (item) => !!item,
  mode,
  tooltip,
  memoValue,
  setValue,
  deps,
  type,
  CustomDrawer,
}: {
  label: string;
  required?: boolean;
  control: Control;
  collection: ArrayOrOne<keyof ICollections>;
  name: string;
  filter?: (item: CollectionsTypeValueMap[CollectionName]) => boolean;
  mode?: 'multiple' | 'tags';
  tooltip?: string;
  memoValue: MutableRefObject<Record<string, unknown>>;
  setValue: UseFormSetValue<Record<typeof name, unknown>>;
  deps?: Array<string>;
  type?: string;
  CustomDrawer?: FC<ComponentProps<typeof DrawerPickArrayOfDto>>;
}) => {
  const { t } = useTranslation([
    'common',
    'assets',
    'forms_assets',
    'forms_contacts',
  ]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const sjDb = useSJDatabase();
  const dtos = Array.isArray(collection)
    ? (collection as Array<keyof ICollections>)
        .map(
          (col) =>
            sjDb.collections[col]
              ?.findMany(
                (item: {
                  id: string;
                  name?: string;
                  nickname?: string;
                  nickName?: string;
                  shortname?: string;
                  isTest?: boolean;
                }) =>
                  !!(
                    item.name ||
                    item.nickName ||
                    item.nickname ||
                    item.shortname
                  ) &&
                  filter(item as T) &&
                  !item.isTest,
              )
              .map(
                (item: {
                  id: string;
                  name?: string;
                  nickname?: string;
                  nickName?: string;
                  shortname?: string;
                }) => ({
                  value: item.id,
                  label:
                    item.name ||
                    item.nickName ||
                    item.nickname ||
                    item.shortname,
                }),
              ) || [],
        )
        .flat()
    : sjDb.collections[collection as keyof ICollections]
        ?.findMany(
          (item: {
            id: string;
            name?: string;
            nickname?: string;
            nickName?: string;
            shortname?: string;
            isTest?: boolean;
          }) =>
            !!(item.name || item.nickName || item.nickname || item.shortname) &&
            filter(item as T) &&
            !item.isTest,
        )
        .map(
          (item: {
            id: string;
            name?: string;
            nickname?: string;
            nickName?: string;
            shortname?: string;
          }) => ({
            value: item.id,
            label:
              item.name || item.nickName || item.nickname || item.shortname,
          }),
        ) || [];

  const filterOption: FilterFunc<{
    label: string | undefined;
    value: string;
  }> = (input: string, option?: { label: string | undefined; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const isValueSetted = useRef(false);

  useEffect(() => {
    if (memoValue.current[name] && !control._formValues[name]) {
      setValue(name, memoValue.current[name]);
    }
  }, [memoValue.current, name]);

  const compileDepsFunction = useCallback(
    (value: unknown, formValues: Record<string, unknown>) => {
      if (!deps?.length) return true;

      const realNameArr = name.split(separator);
      if (realNameArr.length === 1) {
        return isEmpty(value) &&
          !isEmpty(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues[deps[0]],
          )
          ? 'Required field'
          : true;
      } else {
        return isEmpty(value) &&
          !isEmpty(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues[realNameArr[0]]?.[realNameArr[1]]?.[deps[0]]?.[
              realNameArr[3]
            ],
          )
          ? 'Required field'
          : true;
      }
    },
    [deps, name],
  );

  const onSelectChange = (value: unknown, fieldFn: (args: unknown) => void) => {
    memoValue.current[name] = value;
    fieldFn(value);
  };

  const router = useRouter();
  const onAddNewClick = async () => {
    if (!type) return;
    const collectionToCreate = Array.isArray(collection)
      ? collection[0]
      : collection;
    await router.replace({
      pathname: window.location.pathname,
      query: {
        fastAdd: `${collectionToCreate}:${type}`,
      },
    });
  };

  return (
    <div>
      <Typography.Text>{label}</Typography.Text>
      {tooltip && <Tooltip title={t(tooltip)} />}
      <Controller
        name={name}
        control={control}
        rules={{ required, validate: compileDepsFunction }}
        render={({ field, fieldState }) => {
          if (
            !field.value &&
            memoValue.current[name] &&
            !isValueSetted.current
          ) {
            field.value = memoValue.current[name];
            isValueSetted.current = true;
            setValue(name, memoValue.current[name]);
          }
          return (
            <>
              <Form.Item
                validateStatus={fieldState.error ? 'error' : ''}
                help={fieldState.error?.message}
              >
                <Select
                  open={false}
                  placeholder={label}
                  options={dtos}
                  filterOption={filterOption}
                  style={{ width: '100%' }}
                  mode={mode}
                  onClick={() => setIsDrawerOpen(true)}
                  onFocus={(event) => {
                    const rect = event.target.getBoundingClientRect();
                    const scrollTop = document.documentElement.scrollTop;
                    window.scrollTo({
                      top: rect.top + scrollTop - 40,
                      behavior: 'smooth',
                    });
                  }}
                  notFoundContent={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '15px',
                      }}
                    >
                      <p>
                        There is no data added yet, fill the info in the contact
                        section
                      </p>
                      {!!type && <Button text="Add" onClick={onAddNewClick} />}
                    </div>
                  }
                  {...field}
                  onChange={(value) => onSelectChange(value, field.onChange)}
                />
              </Form.Item>

              {/* TODO                 filter={filter} */}
              {CustomDrawer ? (
                <CustomDrawer
                  collection={collection}
                  filter={filter}
                  control={control}
                  memoValue={memoValue}
                  name={name}
                  setValue={setValue}
                  label={label}
                  isOpen={isDrawerOpen}
                  setIsOpen={setIsDrawerOpen}
                  type={type}
                  field={field}
                  mode={mode}
                />
              ) : (
                <DrawerPickArrayOfDto
                  collection={collection}
                  filter={filter}
                  control={control}
                  memoValue={memoValue}
                  name={name}
                  setValue={setValue}
                  label={label}
                  isOpen={isDrawerOpen}
                  setIsOpen={setIsDrawerOpen}
                  type={type}
                  field={field}
                  mode={mode}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

const FileUploadingComponent = ({
  question,
  t,
  name,
  label,
  id,
  control,
  memoizedValue,
  setValue,
}: {
  question: {
    tooltip?: string;
    required?: boolean;
    isMultiple?: boolean;
  };
  label: string;
  name: string;
  id: string;
  t: (key: string) => string;
  control: Control;
  memoizedValue: MutableRefObject<Record<string, unknown>>;
  setValue: UseFormSetValue<Record<typeof name, unknown>>;
}) => {
  const MAX_LENGTH = 30;
  const MAX_SIZE_MB = 3;

  const allowedExtensions = [
    '.heic',
    '.jpeg',
    '.jpg',
    '.png',
    '.doc',
    '.docx',
    '.rtf',
    '.key',
    '.odt',
    '.pdf',
    '.tiff',
    '.ppt',
    '.pptx',
    '.pps',
    '.ppsx',
    '.xls',
    '.xlsx',
    '.csv',
    '.txt',
  ];

  const allowedMimeTypes = [
    'image/heic',
    'image/heif',
    'image/jpeg',
    'image/png',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
    'application/vnd.apple.keynote',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'image/tiff',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'text/plain',
  ];
  const { encryptAndUploadFile, getFileMeta } = useFileEncryptionUpload();

  const { setError, clearErrors } = useFormContext();

  const beforeUpload = (file: RcFile) => {
    const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
    const isExtensionAllowed = allowedExtensions.includes(fileExtension);
    const isMimeTypeAllowed = allowedMimeTypes.includes(file.type);
    const isSizeLimitOk = file.size / 1024 / 1024 < MAX_SIZE_MB;

    if (!isExtensionAllowed || !isMimeTypeAllowed) {
      setError(name, {
        message: 'Invalid file type',
      });

      return false;
    }

    if (!isSizeLimitOk) {
      setError(name, { message: 'File size is more than 50 MB' });
      return false;
    }

    clearErrors(name);
    return true;
  };

  const handleChange: UploadProps['onChange'] = useCallback(
    async (info: UploadChangeParam) => {
      const currentMemoizedValue = memoizedValue.current[name];

      if (info.file.originFileObj && info.file.status !== 'removed') {
        const result = await encryptAndUploadFile(info.file.originFileObj);

        const updatedFormValue =
          currentMemoizedValue && Array.isArray(currentMemoizedValue)
            ? [...currentMemoizedValue, result]
            : [result];

        memoizedValue.current[name] = updatedFormValue;
        setValue(name, updatedFormValue);
      }

      if (info.file.status === 'removed') {
        const updatedFormValue =
          Array.isArray(currentMemoizedValue) &&
          currentMemoizedValue.filter((fileName) => fileName !== info.file.uid);

        memoizedValue.current[name] = updatedFormValue;
        setValue(name, updatedFormValue);
      }
    },
    [encryptAndUploadFile, setValue, name, memoizedValue.current],
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
    </div>
  );

  const currentMemoizedValue = memoizedValue.current[name];

  const fileList =
    currentMemoizedValue && Array.isArray(currentMemoizedValue)
      ? currentMemoizedValue.map((imgName) => ({
          uid: imgName,
          name: getFileMeta(imgName)?.name || 'Document',
        }))
      : currentMemoizedValue
      ? [
          {
            uid: currentMemoizedValue,
            name:
              getFileMeta(currentMemoizedValue as string)?.name || 'Document',
          },
        ]
      : [];

  // init
  return (
    <div key={id}>
      <span>{t(label)}</span>
      {question.tooltip && <Tooltip title={t(question.tooltip)} />}

      <Controller
        name={name}
        control={control}
        rules={{ required: question.required }}
        render={({ field, fieldState }) => {
          return (
            <Form.Item
              validateStatus={fieldState.error ? 'error' : ''}
              help={fieldState.error?.message}
            >
              <StyledUpload
                listType="picture-card"
                className="avatar-uploader"
                multiple={question.isMultiple}
                showUploadList={true}
                fileList={fileList}
                {...field}
                onChange={(event) => {
                  field.onChange();
                  handleChange(event);
                }}
                beforeUpload={beforeUpload}
              >
                {Array.isArray(currentMemoizedValue) && question.isMultiple
                  ? currentMemoizedValue.length >= MAX_LENGTH
                    ? null
                    : uploadButton
                  : currentMemoizedValue
                  ? null
                  : uploadButton}
              </StyledUpload>
            </Form.Item>
          );
        }}
      />
    </div>
  );
};

const AddNew = memo(
  ({
    question,
    form,
    tooltip,
    id,
  }: {
    question: {
      type: QuestionType.RENDER_NEW_BUTTON;
      label: string;
      renderItems: string[];
      uniqKeyPrefix: string;
    };
    control: Control;
    memoValue: MutableRefObject<Record<string, unknown>>;
    form: Record<string, unknown>;
    tooltip?: string;
    id: string;
  }) => {
    const { t } = useTranslation([
      'common',
      'assets',
      'forms_assets',
      'forms_contacts',
    ]);

    const onClick = useCallback(() => {
      const uniqPostfix = nanoid();

      for (const item of question.renderItems) {
        form[
          `add-new${separator}${id}${separator}${item}${separator}${uniqPostfix}`
        ] = form[item];
      }
    }, [question]);

    return (
      <Row>
        <Button text={t(question.label)} onClick={onClick} />
        {tooltip && <Tooltip title={t(tooltip)} />}
      </Row>
    );
  },
);

AddNew.displayName = 'AddNewFormItem';
const InputComponent = ({
  name,
  question,
  t,
  id,
  label,
  placeholder,
  control,
  inputType,
  setValue,
  memoizedValue,
  deps,
  collection,
}: {
  name: string;
  question: ValueType<QuestionType, unknown>;
  t: (text: string) => string;
  id: string;
  label: string;
  placeholder: string;
  control: Control;
  inputType: string;
  setValue: UseFormSetValue<Record<string, unknown>>;
  memoizedValue: MutableRefObject<Record<string, unknown>>;
  deps?: Array<string>;
  collection?: CollectionName;
}) => {
  const isValueSetted = useRef(false);
  const sjDb = useSJDatabase();

  useEffect(() => {
    if (memoizedValue.current[name] && !control._formValues[name]) {
      setValue(name, memoizedValue.current[name]);
    }
  }, [memoizedValue.current, name]);

  const emailValidation = (value: string) => {
    if (collection) {
      const isEmailExist = sjDb.collections[
        collection as keyof ICollections
      ]?.findOne((item: { email?: string }) => {
        return control._formValues.email === item.email;
      });

      if (isEmailExist) {
        return 'Email already exist';
      }
    }

    return !EMAIL_REGEX.test(value) ? 'Email is invalid' : true;
  };

  const compileDepsFunction = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (value: unknown, formValues: Record<string, unknown>) => {
      if (!deps?.length) return true;

      const realNameArr = name.split(separator);

      if (realNameArr.length === 1) {
        return isEmpty(value) &&
          !isEmpty(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues[deps[0]],
          )
          ? 'Required field'
          : true;
      } else {
        return isEmpty(value) &&
          !isEmpty(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues[realNameArr[0]]?.[realNameArr[1]]?.[deps[0]]?.[
              realNameArr[3]
            ],
          )
          ? 'Required field'
          : true;
      }
    },
    [deps, name],
  );

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    fieldFn: ChangeEventHandler<HTMLInputElement>,
  ) => {
    memoizedValue.current[name] = e.target.value;
    fieldFn(e);
  };

  return (
    <div key={id}>
      <span>{label}</span>
      {question.tooltip && <Tooltip title={t(question.tooltip)} />}
      <Controller
        name={name}
        control={control}
        rules={{
          required: getRequiredRule(question),
          validate: {
            compileDepsFunction: compileDepsFunction,
            ...(inputType === 'email' &&
              question.required && { emailValidation: emailValidation }),
          },
        }}
        render={({ field, fieldState }) => {
          if (
            !field.value &&
            memoizedValue.current[name] &&
            !isValueSetted.current
          ) {
            field.value = memoizedValue.current[name];
            isValueSetted.current = true;
            setValue(name, memoizedValue.current[name]);
          }
          return (
            <Form.Item
              validateStatus={fieldState.error ? 'error' : ''}
              help={fieldState.error?.message}
            >
              <Input
                placeholder={placeholder}
                type={inputType}
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e, field.onChange)
                }
              />
            </Form.Item>
          );
        }}
      />
    </div>
  );
};

const PhoneNumberWrapper = styled.div`
  display: grid;
  column-gap: 4px;
  grid-template-columns: 100px auto;
  width: 100%;
`;

const DropdownPhoneNumber = styled(Select)`
  display: grid;
  column-gap: 4px;
  grid-template-columns: 100px auto;
  width: 100%;
`;

const StyledUpload = styled(Upload)`
  &&& {
    .ant-upload.ant-upload-select {
      width: 48px;
      height: 48px;
      border-style: solid;
    }
  }
`;
