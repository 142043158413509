import { useCallback, useEffect, useMemo } from 'react';

import { AssetCategoryToText } from '@/entities/assets/helpers/assets-helpers';
import { useScenariosLazyQuery } from '@/shared/generated/graphql';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { AssetCategory, AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';

export const useAssets = (props?: { delay: number } | undefined) => {
  const store = useSJDatabase();
  const [getScenarios, { data, loading: scenariosLoading }] =
    useScenariosLazyQuery();

  useEffect(() => {
    setTimeout(() => getScenarios(), props?.delay ?? 0);
  }, [getScenarios, props?.delay]);

  const getAssetsList = useCallback(
    (category: AssetCategory | 'all', includeTest: boolean = false) => {
      if (category === 'all') {
        const result = [];

        for (const category_ of Object.keys(AssetCategoryToText)) {
          result.push(
            ...(store.collections.assets?.findMany((asset) => {
              if (includeTest) {
                return asset.category === category_;
              } else {
                return (
                  asset.category === category_ &&
                  (asset.isTest === undefined || !asset.isTest)
                );
              }
            }) || []),
          );
        }
        return result;
      }

      return (
        store.collections.assets?.findMany(
          (asset) => asset.category === category,
        ) || []
      );
    },
    [store],
  );

  const getAsset = useCallback(
    (id: string) => {
      return store.collections.assets?.findOne((asset) => asset.id === id);
    },
    [store],
  );

  const getAssetInformation = useCallback(
    (id: string) => {
      return store.collections.assetInformationDocument?.findOne(
        (document) => document.assetDtoId === id,
      );
    },
    [store],
  );

  const getAssetContacts = useCallback(
    (id: string) => {
      return store.collections.contacts?.findMany(
        (contact) => contact.assetId === id,
      );
    },
    [store],
  );

  const getAssetPrivateDocuments = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string) => {
      const privateDocumentsIds: string[] = [];
      const result = new Set();
      const asset = store.collections.assets?.findOne((item) => item.id === id);

      if (asset) {
        Object.values(asset).forEach((item) => {
          if (
            typeof item === 'string' &&
            item.startsWith('privateDocuments-')
          ) {
            privateDocumentsIds.push(item);
          } else if (Array.isArray(item)) {
            item.forEach((as) => {
              if (
                typeof as === 'string' &&
                as?.startsWith('privateDocuments-')
              ) {
                privateDocumentsIds.push(as);
              }
            });
          }
        });

        if (privateDocumentsIds.length) {
          privateDocumentsIds.forEach((documentId) => {
            const privateDocument = store.collections.privateDocuments?.findOne(
              (document) => {
                return document.id === documentId;
              },
            );
            if (privateDocument) result.add(privateDocument);
          });
        }
        return result;
      } else {
        return undefined;
      }
    },
    [store],
  );

  const getAssetPersonalIdentifiers = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string) => {
      const personalIdentifiersDocumentsIds: string[] = [];
      const result = new Set();
      const asset = store.collections.assets?.findOne((item) => item.id === id);

      if (asset) {
        Object.values(asset).forEach((item) => {
          if (
            typeof item === 'string' &&
            item.startsWith('personalIdentifiersDocuments-')
          ) {
            personalIdentifiersDocumentsIds.push(item);
          } else if (Array.isArray(item)) {
            item.forEach((as) => {
              if (
                typeof as === 'string' &&
                as.startsWith('personalIdentifiersDocuments-')
              ) {
                personalIdentifiersDocumentsIds.push(as);
              }
            });
          }
        });

        if (personalIdentifiersDocumentsIds.length) {
          personalIdentifiersDocumentsIds.forEach((documentId) => {
            const personalIdentifier =
              store.collections.personalIdentifiersDocuments?.findOne(
                (document) => {
                  return document.id === documentId;
                },
              );
            if (personalIdentifier) result.add(personalIdentifier);
          });
        }
        return result;
      } else {
        return undefined;
      }
    },
    [store],
  );

  const deleteAsset = useCallback(
    (id: string) => {
      store.collections.assets?.remove({ id } as AssetDto);
    },
    [store],
  );

  const isAssetWithScenario = useCallback(
    (assetId: string) => {
      return !!data?.scenarios.some((s) => s.dtoIds?.includes(assetId));
    },
    [data],
  );

  const isAssetWithBeneficiary = useCallback(
    (assetId: string, beneficiaries: number[]) => {
      return !!data?.scenarios.some(
        (s) =>
          s.dtoIds?.includes(assetId) &&
          beneficiaries.includes(s.beneficiaryId),
      );
    },
    [data],
  );

  const allBeneficiariesWithScenarios = useMemo(() => {
    const beneficiaries = data?.scenarios.map((el) => el.beneficiaryId);

    return beneficiaries ? [...new Set(beneficiaries)] : [];
  }, [data]);

  const allCoOwners = useMemo(() => {
    const assets = store.collections.assets?.findMany(Boolean);

    if (!assets) return [];

    let coOwners: Array<string> = [];

    for (const asset of assets) {
      if (Array.isArray(asset.ownerDocument)) {
        coOwners = coOwners.concat(asset.ownerDocument);
      }
      if (Array.isArray(asset.coOwners)) {
        for (const coOwner of asset.coOwners) {
          coOwners = coOwners.concat(coOwner.coOwnerDocument);
        }
      }
    }

    if (!coOwners.length) return [];

    return [...new Set(coOwners)];
  }, [store]);

  const isAssetWithOwners = useCallback(
    (assetId: string, owners: string[]) => {
      const asset = store.collections.assets?.findOne((a) => a.id === assetId);

      if (!asset) return false;

      let coOwners: Array<string> = [];

      if (Array.isArray(asset.ownerDocument)) {
        coOwners = coOwners.concat(asset.ownerDocument);
      }
      if (Array.isArray(asset.coOwners)) {
        for (const coOwner of asset.coOwners) {
          coOwners = coOwners.concat(coOwner.coOwnerDocument);
        }
      }

      return coOwners.some((co) => owners.includes(co));
    },
    [store],
  );

  return useMemo(
    () => ({
      getAssetsList,
      getAsset,
      getAssetInformation,
      getAssetContacts,
      getAssetPrivateDocuments,
      getAssetPersonalIdentifiers,
      deleteAsset,
      isAssetWithScenario,
      isAssetWithBeneficiary,
      allBeneficiariesWithScenarios,
      allCoOwners,
      isAssetWithOwners,
      loading: scenariosLoading,
    }),
    [
      getAssetsList,
      getAsset,
      getAssetInformation,
      getAssetContacts,
      getAssetPrivateDocuments,
      getAssetPersonalIdentifiers,
      deleteAsset,
      isAssetWithScenario,
      isAssetWithBeneficiary,
      allBeneficiariesWithScenarios,
      allCoOwners,
      isAssetWithOwners,
      scenariosLoading,
    ],
  );
};
