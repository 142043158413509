import { FC } from 'react';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

type InviteInfoProps = { email: string };
export const InviteInfo: FC<InviteInfoProps> = ({ email }) => {
  return (
    <Surface>
      <Title>If you are not receiving the email, please try following:</Title>

      <ol>
        <li>1. Check the spam folder in your email service.</li>
        <li>2. Wait 5 minutes. The letter may arrive with a delay.</li>

        <li>
          3. Please check that the email is correct: <b>{email}</b>
        </li>
      </ol>
    </Surface>
  );
};

const Title = styled.b`
  color: ${COLORS.colorPrimary};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const Surface = styled.div`
  background: #eaedf1;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  & > ol > li {
    list-style-type: none;
    font-family: Roboto;
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.colorPrimary};
  }
`;
