import { CollectionName } from '@/shared/lib/sj-orm/constants';

export const collectionsToPull = [
  CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
  CollectionName.PRIVATE_DOCUMENTS,
  CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
  CollectionName.ASSET_INFORMATION_DOCUMENT,
  CollectionName.ASSETS,
  CollectionName.CONTACTS,
  CollectionName.BENEFICIARIES,
  CollectionName.ASSISTANT_MNEMONIC,
  CollectionName.DELEGATING_REQUESTS,
  CollectionName.MY_HUB,
  CollectionName.SCENARIO_META_INFO,
  CollectionName.NOTIFICATION,
  CollectionName.SOWE,
  CollectionName.SOWE_ATTACHMENTS,
  CollectionName.ENCRYPTED_FILE_KEY,
  CollectionName.FILE_META_INFO,
  CollectionName.MIGRATIONS,
];
