import { useCallback, useMemo } from 'react';

import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';

export enum DocumentsGroups {
  beneficiaryPersonal = 'beneficiaryPersonal',
  private = 'private',
  myPersonal = 'myPersonal',
}

type TypeToDtoType = {
  [DocumentsGroups.beneficiaryPersonal]: PersonalIdentifierDocumentDto[];
  [DocumentsGroups.private]: PrivateDocumentDto[];
  [DocumentsGroups.myPersonal]: PersonalIdentifierDocumentDto[];
};

type TypeDocToDtoType = PrivateDocumentDto | PersonalIdentifierDocumentDto;

export const useDocuments = () => {
  const { keyPair } = useKeyPairStore();
  const sjDb = useSJDatabase();

  const sizes = useMemo(() => {
    return {
      beneficiaryPersonal:
        sjDb.collections.personalIdentifiersDocuments?.findMany(
          (item) =>
            !!(item && item.beneficiaryId && item.beneficiaryId !== 'me'),
        ).length || 0,
      private:
        sjDb.collections.privateDocuments?.findMany(() => true).length || 0,
      myPersonal:
        sjDb.collections.personalIdentifiersDocuments?.findMany(
          (item) => item && item.beneficiaryId === 'me',
        ).length || 0,
    };
  }, [keyPair, sjDb]);

  const getList = useCallback(
    <T extends keyof TypeToDtoType>(type: T): TypeToDtoType[T] => {
      if (type === DocumentsGroups.myPersonal) {
        return (sjDb.collections.personalIdentifiersDocuments?.findMany(
          (item) => !!item && item.beneficiaryId === 'me',
        ) || []) as TypeToDtoType[T];
      }
      if (type === DocumentsGroups.beneficiaryPersonal) {
        return (sjDb.collections.personalIdentifiersDocuments?.findMany(
          (item) =>
            !!(item && item.beneficiaryId && item.beneficiaryId !== 'me'),
        ) || []) as TypeToDtoType[T];
      }
      if (type === DocumentsGroups.private) {
        return (sjDb.collections.privateDocuments?.findMany(() => true) ||
          []) as TypeToDtoType[T];
      }
      log.warn('Unsupported doc type', type);
      return [];
    },
    [keyPair, sjDb],
  );

  const getDocument = useCallback(
    //todo return type fro document
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (id: string): any | undefined => {
      const pid = sjDb.collections.personalIdentifiersDocuments?.findOne(
        (item) => item.id === id,
      );
      if (pid) return pid as TypeDocToDtoType;
      const pd = sjDb.collections.privateDocuments?.findOne(
        (item) => item.id === id,
      );
      if (pd) return pd as TypeDocToDtoType;
      return undefined;
    },
    [keyPair, sjDb],
  );

  const attachDocument = useCallback(
    (docId: DtoId, file: string) => {
      const pid = sjDb.collections.personalIdentifiersDocuments?.findOne(
        (item) => item.id === docId,
      );

      if (pid) {
        sjDb.collections.personalIdentifiersDocuments?.update({
          ...pid,
          attachments: [...(pid?.attachments ?? []), file],
        });
        return;
      }

      const pd = sjDb.collections.privateDocuments?.findOne(
        (item) => item.id === docId,
      );

      if (pd) {
        sjDb.collections.privateDocuments?.update({
          ...pd,
          scan: file,
        });
      }
    },
    [keyPair, sjDb],
  );

  const deleteDocument = useCallback(
    (id: string) => {
      sjDb.collections.privateDocuments?.remove({ id } as PrivateDocumentDto);
      sjDb.collections.personalIdentifiersDocuments?.remove({
        id,
      } as PersonalIdentifierDocumentDto);
    },
    [sjDb, keyPair],
  );

  return useMemo(
    () => ({ sizes, getList, getDocument, deleteDocument, attachDocument }),
    [sizes, getList, getDocument, attachDocument],
  );
};
