import { useCallback, useMemo } from 'react';

// import {
//   ScenarioMode,
//   ScenarioQuery,
//   useCreateScenarioMutation,
//   useGetBeneficiariesLazyQuery,
// } from '@/generated/graphql';
// import { getDtoRelatedToTargetDto } from '@/services/helpers/asset.helper';
// import { log } from '@/services/log';
// import { CollectionName } from '@/services/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
// import { AssetDto } from '@/services/sj-orm/models/asset.dto';
// import { BaseDto } from '@/services/sj-orm/models/base.dto';
// import { NotificationType } from '@/services/sj-orm/models/notification.dto';
// import { ScenarioMetaInfoDto } from '@/services/sj-orm/models/scenario-meta-info.dto';
// import { sleep } from '@/services/utils';
// import { useKeyPairStore } from '@/store/decrypted-keypair.store';

export const useScenarios = () => {
  const store = useSJDatabase();
  // const [createScenario] = useCreateScenarioMutation();
  // const [getBeneficiarie] = useGetBeneficiariesLazyQuery();
  // const { keyPair } = useKeyPairStore();

  const getScenarioName = useCallback(
    (scenarioId: string) => {
      return store.collections.scenarioMetaInfos?.findOne(
        (scenario) => scenario.centralDbId === scenarioId,
      );
    },
    [store],
  );

  // const handleRunTestScenario = async (
  //   dataScenario: ScenarioQuery,
  //   assetData: AssetDto,
  //   scenarioName: string,
  //   assetDtoId: string,
  //   // eslint-disable-next-line sonarjs/cognitive-complexity
  // ) => {
  //   try {
  //     const publicKey = keyPair?.publicKey;
  //     if (!publicKey) return;
  //     if (dataScenario) {
  //       if (!assetData) return;
  //
  //       const newAsset: AssetDto = {
  //         id: store.generateId(CollectionName.ASSETS), // or just sjDatabase.generateId()
  //         name: assetData.nickName ?? assetData.nickname ?? assetData.name,
  //         category: assetData.category,
  //         isTest: true,
  //       };
  //       const asset = store.collections.assets?.create(newAsset, [publicKey]);
  //
  //       const creationResult = await createScenario({
  //         variables: {
  //           beneficiaryProfileId: dataScenario.scenario?.beneficiaryId,
  //           triggerValue: dataScenario.scenario?.triggerValue,
  //           assetAndRelatedDtoIds: dataScenario.scenario?.dtoIds,
  //           triggerType: dataScenario.scenario?.triggerType,
  //           mode: ScenarioMode.Test,
  //           notificationValue: dataScenario.scenario?.notificationValue,
  //           notificationPeriod: dataScenario.scenario?.notificationPeriod,
  //           notificationTime: dataScenario.scenario?.notificationTime,
  //         },
  //       });
  //
  //       store.collections.scenarioMetaInfos?.create(
  //         {
  //           id: store.generateId(CollectionName.SCENARIO_META_INFO),
  //           centralDbId: creationResult.data?.createScenario.id,
  //           name: scenarioName,
  //         } as ScenarioMetaInfoDto,
  //         [publicKey],
  //       );
  //
  //       const beneficiaries = await getBeneficiarie();
  //       const beneficiary = beneficiaries.data?.getBeneficiaries.find(
  //         (beneficiary_) =>
  //           beneficiary_.id === dataScenario.scenario?.beneficiaryId,
  //       );
  //
  //       if (beneficiary?.publicKey && creationResult.data?.createScenario.id) {
  //         store.collections.assets?.putPublicKey(
  //           asset ? asset.id : '',
  //           beneficiary?.publicKey || '',
  //         );
  //
  //         const collectionNames = [
  //           CollectionName.ASSETS,
  //           CollectionName.CONTACTS,
  //           // todo add other collections
  //         ];
  //
  //         const expectedFieldNames = ['assetDtoId', 'assetId'];
  //
  //         const relatedDtoToAsset = new Map<CollectionName, Array<BaseDto>>();
  //         for (const collectionName of collectionNames) {
  //           const relatedDtoArray = getDtoRelatedToTargetDto(
  //             store.collections[collectionName]!.findMany(() => true),
  //             expectedFieldNames,
  //             assetDtoId,
  //           );
  //
  //           relatedDtoToAsset.set(collectionName, relatedDtoArray);
  //         }
  //
  //         // pass through all related dto and put public key
  //         const relatedDtoToAssetKeys = relatedDtoToAsset.keys();
  //
  //         for (const key of relatedDtoToAssetKeys) {
  //           const relatedDtoArray = relatedDtoToAsset.get(key);
  //           if (!relatedDtoArray) {
  //             continue;
  //           }
  //
  //           for (const relatedDto of relatedDtoArray) {
  //             store.collections[key]?.putPublicKey(
  //               relatedDto.id,
  //               beneficiary?.publicKey || '',
  //             );
  //           }
  //         }
  //
  //         log.debug('Test scenario is created');
  //       } else {
  //         log.error('No beneficiary public key');
  //         api.error({
  //           message: t('scenarios:create.step3.handleChangeScenario.message'),
  //           description: t(
  //             'scenarios:create.step3.handleChangeScenario.description',
  //           ),
  //           placement: 'top',
  //         });
  //         await sleep();
  //       }
  //     }
  //   } catch (e) {
  //     log.error(t('scenarios:create.step3.creationError'));
  //   }
  // };

  return useMemo(
    () => ({
      getScenarioName,
    }),
    [getScenarioName],
  );
};
