import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const ErrorText: FC<PropsWithChildren> = ({ children }) => {
  return <Error>{children}</Error>;
};

const Error = styled.p`
  color: #ee0000;
  font-size: 12px;
`;
