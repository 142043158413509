import { create } from 'zustand';

import { EncryptionType } from '../lib/secure-json/core/crypto-core/types';

export type WhoamiStore = {
  publicKey: string | null;
  encryptionType: EncryptionType | null;
  setPublicKey: (publicKey: string | null) => void;
  setEncryptionType: (encryptionType: EncryptionType) => void;
  clear: () => void;
};

export const useWhoamiStore = create<WhoamiStore>((set) => ({
  publicKey: null,
  encryptionType: null,
  setPublicKey: (publicKey) => set({ publicKey }),
  setEncryptionType: (encryptionType) => set({ encryptionType }),
  clear: () => set({ publicKey: null }),
}));
