import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

import { CollectionName } from '@/shared/lib/sj-orm/constants';

import { SerializedCollection } from './core/types';
import { getCollections } from './helpers';

type UUID = string;
export type SyncCollection = {
  collectionName: CollectionName;
  collection: SerializedCollection;
};

export enum SyncQueueJobMethod {
  PUSH = 'PUSH',
  PULL = 'PULL',
  SINGLE_PULL = 'SINGLE_PULL',
}
export interface ISyncQueueJob {
  id: UUID;
  createdAt: Date;
  method: SyncQueueJobMethod;
  collections: Array<SyncCollection>;
}

export type IAddJobProps =
  | {
      method: SyncQueueJobMethod.PULL;
    }
  | {
      method: SyncQueueJobMethod.PUSH;
      collections: Array<{
        collectionName: CollectionName;
        collection: SerializedCollection;
      }>;
    }
  | {
      method: SyncQueueJobMethod.SINGLE_PULL;
      collectionName: CollectionName;
    };

export interface ISyncQueueStore {
  jobs: ISyncQueueJob[];
  addJob: (job: IAddJobProps) => void;
  removeJob: (jobId: UUID) => void;
  clear: () => void;
}

export const useSyncQueueStore = create<ISyncQueueStore>((set) => ({
  jobs: [],
  // eslint-disable-next-line sonarjs/cognitive-complexity
  addJob: (job: IAddJobProps): void => {
    set((state) => {
      // find existing job with same collections
      const jobExists = state.jobs.find((existingJob) => {
        // find pull jobs
        if (job.method === SyncQueueJobMethod.PULL) {
          return (
            existingJob.method === SyncQueueJobMethod.PULL &&
            existingJob.collections.length === 0
          );
        }

        if (job.method === SyncQueueJobMethod.SINGLE_PULL) {
          const isPull = existingJob.method === SyncQueueJobMethod.SINGLE_PULL;
          const everySameCollections = existingJob.collections.every(
            (existingCollection) => {
              return existingCollection.collectionName === job.collectionName;
            },
          );

          return isPull && everySameCollections;
        }

        // TODO https://ownerone.atlassian.net/browse/WA-1060
        // // find push jobs
        // if (job.method === SyncQueueJobMethod.PUSH) {
        //   return (
        //     existingJob.method === SyncQueueJobMethod.PUSH &&
        //     existingJob.collections.every((existingCollection) => {
        //       return job.collections.some((newCollection) => {
        //         return (
        //           existingCollection.collectionName ===
        //           newCollection.collectionName
        //         );
        //       });
        //     })
        //   );
        // }

        return false;
      });

      // if found, do nothing
      if (jobExists) {
        return {
          jobs: state.jobs,
        };
      }

      const newJob: ISyncQueueJob = {
        ...job,
        id: uuidv4(),
        createdAt: new Date(),
        collections: getCollections(job),
      };

      return {
        jobs: [...state.jobs, newJob],
      };
    });
  },
  removeJob: (jobId: UUID) => {
    set((state) => {
      const filteredJobs = state.jobs.filter((iJob) => iJob.id !== jobId);
      return { jobs: filteredJobs };
    });
  },
  clear: () => set({ jobs: [] }),
}));
