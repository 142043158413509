import { IconType } from '@/shared/icons/types';

export const Cross = ({ ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...rest}
  >
    <path
      d="M32.1343 30.3656C32.2505 30.4817 32.3426 30.6196 32.4055 30.7713C32.4683 30.9231 32.5007 31.0857 32.5007 31.25C32.5007 31.4142 32.4683 31.5768 32.4055 31.7286C32.3426 31.8803 32.2505 32.0182 32.1343 32.1343C32.0182 32.2505 31.8803 32.3426 31.7286 32.4055C31.5768 32.4683 31.4142 32.5007 31.25 32.5007C31.0857 32.5007 30.9231 32.4683 30.7713 32.4055C30.6196 32.3426 30.4817 32.2505 30.3656 32.1343L20 21.7671L9.63434 32.1343C9.39979 32.3689 9.08167 32.5007 8.74996 32.5007C8.41826 32.5007 8.10014 32.3689 7.86559 32.1343C7.63104 31.8998 7.49927 31.5817 7.49927 31.25C7.49927 30.9183 7.63104 30.6001 7.86559 30.3656L18.2328 20L7.86559 9.63434C7.63104 9.39979 7.49927 9.08167 7.49927 8.74996C7.49927 8.41826 7.63104 8.10014 7.86559 7.86559C8.10014 7.63104 8.41826 7.49927 8.74996 7.49927C9.08167 7.49927 9.39979 7.63104 9.63434 7.86559L20 18.2328L30.3656 7.86559C30.6001 7.63104 30.9183 7.49927 31.25 7.49927C31.5817 7.49927 31.8998 7.63104 32.1343 7.86559C32.3689 8.10014 32.5007 8.41826 32.5007 8.74996C32.5007 9.08167 32.3689 9.39979 32.1343 9.63434L21.7671 20L32.1343 30.3656Z"
      fill="currentColor"
    />
  </svg>
);
