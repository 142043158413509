import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import {
  onMessageListener,
  requestForToken,
} from '@/shared/services/firebase.service';

import * as firebaseConfig from '../../../firebase.config';

const FirebaseNotifications = () => {
  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        unsubscribe = onPermissionGranted();
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            //do nothing
            unsubscribe = onPermissionGranted();
          }
        });
      }
    }

    return () => unsubscribe?.();
  }, []);

  const onPermissionGranted = (): (() => void) => {
    if ('serviceWorker' in navigator) {
      // Register the SW
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          return registration;
        })
        .catch(console.log);
    }
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    requestForToken(messaging);
    const unsubscribeOnMessage = onMessageListener(messaging);

    return () => {
      unsubscribeOnMessage();
    };
  };

  return null;
};

export default FirebaseNotifications;
