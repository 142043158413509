export const COLORS = {
  black: '#000000',
  grey: '#D0D4DC',
  grey50percent: '#0d172480',
  white: '#FFFFFF',
  success: '#019F3C',
  warning: '#F29100',
  danger: '#D91528',
  link: '#0055FF',

  colorLink: '#00043099',
  colorLinkHover: '#000430',
  colorLinkActive: '#000430a3',

  colorIcon: '#000430a3',
  colorIconHover: '#26251C',

  colorPrimaryText: '#000430',
  colorPrimaryTextActive: '#0f1723cc',
  colorPrimaryTextHover: '#0f1723cc',

  colorTextBase: '#0F1723',
  colorTextSecondary: '#2F4F7C',
  colorTextTertiary: '#2F4F7C',
  colorTextQuaternary: '#2F4F7C',

  colorBgBase: '#FFFFFF',
  colorBgLayout: '#FFFFFF',
  colorBgSpotlight: '#17150D',
  controlItemBgHover: '#0004303d',
  controlItemBgActive: '#0004301f',
  controlItemBgActiveHover: '#2F4F7C',

  colorPrimaryActive: '#000430',
  colorPrimary: '#000430',
  colorPrimaryHover: '#000430cc',
  colorPrimaryBorder: '#0004301f',
  colorPrimaryBorderFocus: '#000430cc',
  colorPrimaryBorderHover: '#000430cc',

  colorPrimaryBg: '#F4F7FB',
  colorPrimaryBgHover: '#0004301f',

  colorBgContainer: '#FFFFFF',

  colorFillContent: '#0004301f',
  colorTextDescription: '#000430a3',
  colorNeutral: '#595956',
  colorNeutral2: '#F2F2EB',
  colorBorder: '#0004301f',

  colorSelectActiveOption: '#E6EEFF',

  colorButton: '#0D1724',
  light300: '#EAEDF1',

  colorGoldBg: '#FFFBE6',
  colorGoldBorder: '#FFE58F',

  error: '#FF4D4F',

  chartColors: [
    '#4CAF50',
    '#FFC107',
    '#2196F3',
    '#FF5722',
    '#9C27B0',
    '#00BCD4',
    '#FFEB3B',
    '#E91E63',
    '#795548',
    '#607D8B',
    // repeat TODO rework
    '#4CAF50',
    '#FFC107',
    '#2196F3',
    '#FF5722',
    '#9C27B0',
    '#00BCD4',
    '#FFEB3B',
    '#E91E63',
    '#795548',
    '#607D8B',
  ],
};
