import { FC, useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import styled from 'styled-components';

import { INTL_LOCALE } from '@/shared/constants/constants';

import { getLocalStorageKey, SetInviteUser } from './type';

const { Text: AntDText } = Typography;

type InviteButtonProps = {
  code?: string;
  createdAt?: Date;
  email?: string;
  id?: string | number;
  invitedUsers: Array<string>;
  isFirstInvite: boolean;
  setModalInviteUserState: SetInviteUser;
};

const TIMER_PERIOD_SECONDS = 60;

export const InviteButton: FC<InviteButtonProps> = ({
  code,
  createdAt,
  email,
  id,
  invitedUsers,
  isFirstInvite,
  setModalInviteUserState,
}) => {
  const [secondsLeft, setSecondsLeft] = useState<number | null>(null);

  if (!createdAt || !code || !email || !id) {
    return null;
  }

  const calculateSecondsLeft = () => {
    const lastInvited = localStorage.getItem(getLocalStorageKey(email));

    if (lastInvited && !Number.isNaN(Number(lastInvited))) {
      const now = new Date().getTime();
      const secondsPassed = Math.floor((now - Number(lastInvited)) / 1000);
      const result = TIMER_PERIOD_SECONDS - secondsPassed;

      if (result <= 0) {
        setSecondsLeft(null);

        setTimeout(() => {
          localStorage.removeItem(getLocalStorageKey(email));
        }, 2000);
      }

      return result;
    }

    return null;
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      const remainingTime = calculateSecondsLeft();

      if (remainingTime && remainingTime > 0) {
        setSecondsLeft(remainingTime);
      }
    }, 1000);

    return () => clearInterval(timerId);
  });

  if (Number(secondsLeft) > 1) {
    return <Text>{`Resend in ${secondsLeft} seconds`}</Text>;
  }

  // 2 seconds for avoid flash button
  if (
    invitedUsers.some((userEmail) => userEmail === email) &&
    isFirstInvite &&
    Number(secondsLeft) < 2
  ) {
    return <Text>Invited {new Date().toLocaleDateString(INTL_LOCALE)}</Text>;
  }

  return (
    <StyledButton
      htmlType="button"
      type="link"
      size="small"
      style={{ color: '#0055FF' }}
      onClick={() => {
        setModalInviteUserState({
          code,
          email,
          id: String(id),
          isOpen: true,
          isFirstInvite,
        });
      }}
    >
      {isFirstInvite ? 'Send invite' : 'Resend invite'}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  &&& {
    height: 12px;
    font-size: 10px;
  }
`;

const Text = styled(AntDText)`
  &&& {
    display: flex;
    align-items: center;
    color: #445371;
    padding-left: 12px;
    text-overflow: ellipsis;
    font-family: 'Avenir Next Cyr';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.4px;
  }
`;
