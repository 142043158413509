import { IconType } from '@/shared/icons/types';

export const Warning = ({ ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...rest}
  >
    <path
      d="M22.5 31.25C22.5 31.7445 22.3534 32.2278 22.0787 32.6389C21.804 33.0501 21.4135 33.3705 20.9567 33.5597C20.4999 33.7489 19.9972 33.7984 19.5123 33.702C19.0273 33.6055 18.5819 33.3674 18.2322 33.0178C17.8826 32.6681 17.6445 32.2227 17.548 31.7377C17.4516 31.2528 17.5011 30.7501 17.6903 30.2933C17.8795 29.8365 18.2 29.446 18.6111 29.1713C19.0222 28.8966 19.5055 28.75 20 28.75C20.663 28.75 21.2989 29.0134 21.7678 29.4822C22.2366 29.9511 22.5 30.587 22.5 31.25ZM20 25C20.3315 25 20.6495 24.8683 20.8839 24.6339C21.1183 24.3995 21.25 24.0815 21.25 23.75V7.5C21.25 7.16848 21.1183 6.85054 20.8839 6.61612C20.6495 6.3817 20.3315 6.25 20 6.25C19.6685 6.25 19.3505 6.3817 19.1161 6.61612C18.8817 6.85054 18.75 7.16848 18.75 7.5V23.75C18.75 24.0815 18.8817 24.3995 19.1161 24.6339C19.3505 24.8683 19.6685 25 20 25Z"
      fill="currentColor"
    />
  </svg>
);
