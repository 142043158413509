import {
  EncryptionType,
  PublicKey,
} from '@/shared/lib/secure-json/core/crypto-core/types';
import {
  DecryptedData,
  ISetParameters,
  Key,
  Permission,
  RecordNotFoundError,
  Value,
} from '@/shared/lib/secure-json/core/secure-json-collection/types';

import { CryptoCore } from '../crypto-core';

export class SecureJsonCollection<T = DecryptedData> {
  public items = new Map<Key, Value>(); // todo move private
  protected readonly cryptoCore: CryptoCore;

  constructor(public readonly encryptionType: EncryptionType) {
    this.cryptoCore = new CryptoCore(this.encryptionType);
  }

  public getDataToSerialize() {
    return { items: this.items };
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity,complexity
  public async set(input: ISetParameters): Promise<T> {
    const existingItem = this.items.get(input.key);

    if (existingItem) {
      if (input.publicKeys) {
        throw new Error(
          'Record already exists, use putPublicKey or deletePublicKey instead',
        );
      }

      if (input.permissions) {
        throw new Error(
          'Record already exists, use putPermission or deletePermission instead',
        );
      }

      if (existingItem.encryptedData) {
        throw new Error('You are not allowed to change encrypted data');
      }

      existingItem.decryptedData = input.data;
      this.items.set(input.key, existingItem);
    } else {
      if (!input.publicKeys || input.publicKeys.length === 0) {
        throw new Error('Item does not exist, please provide public keys');
      }

      const permissions =
        input.permissions || new Map<PublicKey, Set<Permission>>();

      if (permissions.size === 0) {
        for (const publicKey of input.publicKeys) {
          permissions.set(
            publicKey,
            new Set<Permission>([Permission.READ, Permission.WRITE]),
          );
        }
      }

      const newSymmetricKey = CryptoCore.aes.generateKey();
      const encryptedSymmetricKeys = new Map<PublicKey, string>();

      for await (const publicKey of input.publicKeys) {
        const encryptedSymmetricKey = await this.cryptoCore.asymmetric.encrypt(
          newSymmetricKey,
          publicKey,
        );
        encryptedSymmetricKeys.set(publicKey, encryptedSymmetricKey);
      }

      const newValue: Value = {
        decryptedData: input.data,
        encryptedData: undefined,
        decryptedSymmetricKey: newSymmetricKey,
        encryptedSymmetricKeys,
        permissions,
      };

      this.items.set(input.key, newValue);
    }

    return input.data;
  }

  public get(key: Key): T | undefined {
    return this.items.get(key)?.decryptedData;
  }

  public delete(key: Key): void {
    this.items.delete(key);
  }

  public keys(): Key[] {
    return [...this.items.keys()];
  }

  public values(): Value[] {
    return [...this.items.values()];
  }

  public size(): number {
    return this.items.size;
  }

  public clear(): void {
    this.items.clear();
  }

  public getPublicKeys(key: Key): Array<PublicKey> | undefined {
    const item = this.items.get(key);
    if (!item) {
      throw new RecordNotFoundError();
    }
    if (item.encryptedSymmetricKeys.keys()) {
      return Array.from(item.encryptedSymmetricKeys.keys());
    } else {
      return undefined;
    }
  }

  public async putPublicKey(
    key: Key,
    publicKey: PublicKey,
    encryptionType?: EncryptionType,
  ): Promise<boolean> {
    const item = this.items.get(key);
    if (!item) {
      throw new RecordNotFoundError();
    }

    if (!item.decryptedSymmetricKey) {
      throw new Error('Item does not have decrypted symmetric key');
    }

    const currentSymmetricKey = item.encryptedSymmetricKeys.get(publicKey);
    if (currentSymmetricKey) {
      return true;
    }

    // TODO crypto remove this hack for different encryption (CO+FM)
    if (encryptionType && encryptionType !== this.encryptionType) {
      const newCryptoCore = new CryptoCore(encryptionType);
      const encryptedSymmetricKey = await newCryptoCore.asymmetric.encrypt(
        item.decryptedSymmetricKey,
        publicKey,
      );
      item.encryptedSymmetricKeys.set(publicKey, encryptedSymmetricKey);

      this.items.set(key, item);
      return true;
    }
    // TODO crypto remove this hack for different encryption (CO+FM)

    const encryptedSymmetricKey = await this.cryptoCore.asymmetric.encrypt(
      item.decryptedSymmetricKey,
      publicKey,
    );
    item.encryptedSymmetricKeys.set(publicKey, encryptedSymmetricKey);

    this.items.set(key, item);
    return true;
  }

  public deletePublicKey(key: Key, publicKey: PublicKey): void {
    const item = this.items.get(key);
    if (!item) {
      throw new RecordNotFoundError();
    }

    const encryptedSymmetricKey = item.encryptedSymmetricKeys.get(publicKey);

    if (!encryptedSymmetricKey) {
      return;
    }

    item.encryptedSymmetricKeys.delete(publicKey);
    this.items.set(key, item);
  }

  public getPermissions(key: Key): Map<PublicKey, Set<Permission>> | undefined {
    return this.items.get(key)?.permissions;
  }

  public putPermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    const item = this.items.get(key);
    if (!item) {
      throw new RecordNotFoundError();
    }

    const currentPermissions = item.permissions.get(publicKey);
    if (currentPermissions?.has(permission)) {
      return;
    }

    const permissions = currentPermissions || new Set<Permission>();
    permissions.add(permission);
    item.permissions.set(publicKey, permissions);

    this.items.set(key, item);
  }

  public deletePermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    const item = this.items.get(key);
    if (!item) {
      throw new RecordNotFoundError();
    }

    const permissions = item.permissions.get(publicKey);
    if (!permissions?.has(permission)) {
      return;
    }

    permissions.delete(permission);

    this.items.set(key, item);
  }
}
