import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';

type ISettingsState = {
  encryptedMnemonic: string | null;
  setEncryptedMnemonic: (mnemonic: string, password: string) => void;
  clear: () => void;
};

// TODO rename mnemonic store / etc
export const useSettingsStore = create(
  persist<ISettingsState>(
    (set) => ({
      encryptedMnemonic: null,
      setEncryptedMnemonic: (mnemonic, password) => {
        if (!password) {
          throw Error('[useSettingsStore] empty password');
        }

        // TODO clean mnemonic before encrypt and save
        const encryptedMnemonic = CryptoCore.aes.encrypt(mnemonic, password);
        set({ encryptedMnemonic });
      },
      clear: () => set({ encryptedMnemonic: undefined }),
    }),
    {
      name: 'settings-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
