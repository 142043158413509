import { useCallback } from 'react';
//import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { Button, FastTrackMenuButton } from '@/shared/components';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { DelegatingRequestStatus } from '@/shared/lib/sj-orm/models/delegating-request.dto';
import { DocumentType } from '@/shared/lib/sj-orm/models/document/document-base.dto';
import { useAuthStore } from '@/shared/store/auth.store';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

type DelegateButtonType = {
  collectionName?: CollectionName;
  overrideText?: string;
  collectionData?: Omit<BaseDto, 'id'> & Record<string, unknown>;
  documentType?: DocumentType;
  relatedEntity?: {
    collectionName: CollectionName;
    dtoId: DtoId;
  };
  step?: number;
  createOrFind?: 'create' | 'findOne';
  id?: string;
  withoutCreation?: boolean;
  onClick?: () => void;
  type?: 'default' | 'fastTrack';
  title?: string;
  description?: string;
};

export const DelegateButton = ({
  collectionName,
  overrideText,
  collectionData,
  documentType,
  relatedEntity,
  step,
  createOrFind = 'create',
  id,
  withoutCreation = false,
  onClick,
  type = 'default',
  title = '',
  description = '',
}: DelegateButtonType) => {
  const { keyPair } = useKeyPairStore();
  const { account } = useAuthStore();
  const sjDatabase = useSJDatabase();
  const router = useRouter();
  const { t } = useTranslation(['common']);

  const onDelegateClick = useCallback(async () => {
    if (!keyPair?.publicKey) throw new Error('Public key is not defined');
    const item = collectionName
      ? createOrFind === 'create'
        ? await sjDatabase.collections[collectionName]?.create(
            {
              id: sjDatabase.generateId(collectionName),
              ...collectionData,
            } as never,
            [keyPair.publicKey],
          )
        : sjDatabase.collections[collectionName]?.findOne((i) => i.id === id)
      : undefined;

    if (!item?.id) throw new Error('Error while creating pid');

    const delegatingRequest =
      await sjDatabase.collections.delegatingRequests?.create(
        {
          id: sjDatabase.generateId(CollectionName.DELEGATING_REQUESTS),
          status: DelegatingRequestStatus.OPENED_TO_EDIT,
          relatedEntity: relatedEntity ?? {
            collectionName: collectionName as CollectionName,
            dtoId: item?.id ?? '',
          },
          from: account ?? {},
          items:
            item && documentType
              ? [
                  {
                    documentType: documentType,
                    value: item,
                    ...(documentType === DocumentType.ASSET_INFORMATION && {
                      step: step,
                    }),
                  },
                ]
              : [],
        },
        [keyPair.publicKey],
      );

    await router.push('/delegate/[slug]', `/delegate/${delegatingRequest?.id}`);
  }, [sjDatabase, keyPair, step]);

  if (type === 'fastTrack' && onClick) {
    return (
      <FastTrackMenuButton
        title={title}
        description={description}
        onClick={!withoutCreation ? onDelegateClick : onClick}
      />
    );
  }

  return (
    <Button
      data-testId={'delegateBtn'}
      text={overrideText || t('common:delegateToAssistant')}
      onClick={!withoutCreation ? onDelegateClick : onClick}
    />
  );
};
