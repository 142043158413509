// import { Aes } from '@/shared/lib/secure-json/core/crypto-core/aes';
// import { Rsa } from '@/shared/lib/secure-json/core/crypto-core/rsa';
// import { Ecies } from '@/shared/lib/secure-json/core/crypto-core/ecies';
import * as libCrypto from '@ownerone/lib-crypto';
import forge from 'node-forge';

import {
  AsymmetricEncryption,
  EncryptionType,
  KeyPair,
  SymmetricKey,
} from './types';
import { DecryptedFile, EncryptedFile } from './types';

interface ICryptoCore {
  asymmetric: AsymmetricEncryption;
  // static aes: typeof libCrypto.aes;
}

const ENCRYPTED_FILE_DELIMITER = '<!_!>ENCRYPTED_FILE_DELIMITER<!_!>';

export class CryptoCore implements ICryptoCore {
  public static aes = libCrypto.aes;
  public readonly asymmetric: AsymmetricEncryption;

  constructor(type?: EncryptionType) {
    this.asymmetric = type === 'ecies' ? libCrypto.ecies : libCrypto.rsa;
  }

  public static encryptMnemonic(mnemonic: string, password: string): string {
    return CryptoCore.aes.encrypt(mnemonic, password);
  }

  public static decryptMnemonic(mnemonic: string, password: string): string {
    return CryptoCore.aes.decrypt(mnemonic, password);
  }

  public async encryptedMnemonicToKeyPair(
    encryptedMnemonic: string,
    password: string,
  ): Promise<KeyPair> {
    const mnemonic = CryptoCore.decryptMnemonic(encryptedMnemonic, password);
    return await this.mnemonicToKeyPair(mnemonic);
  }

  public async mnemonicToKeyPair(mnemonic: string): Promise<KeyPair> {
    return await this.asymmetric.mnemonicToKeyPair(mnemonic);
  }

  public async encryptFile(
    file: File,
    key: SymmetricKey,
  ): Promise<EncryptedFile> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const plaintext = new Uint8Array(reader.result as ArrayBuffer);
        const chunks = this.chunkArray(plaintext, 1000);
        const encodedData = chunks.map((chunk) =>
          forge.util.binary.raw.encode(chunk),
        );
        const dataWithMimeType = `${
          file.type
        }${ENCRYPTED_FILE_DELIMITER}${encodedData.join('')}`;

        resolve(CryptoCore.aes.encrypt(dataWithMimeType, key));
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  public async decryptFile(
    file: EncryptedFile,
    key: SymmetricKey,
    fileName?: string,
  ): Promise<DecryptedFile> {
    const dataWithMimeType = CryptoCore.aes.decrypt(file, key);
    const [mimeType, data] = dataWithMimeType.split(ENCRYPTED_FILE_DELIMITER);
    const arrayBuffer = new Uint8Array(forge.util.binary.raw.decode(data))
      .buffer;
    return new File([arrayBuffer], fileName || 'decrypted', { type: mimeType });
  }

  private chunkArray(input: Uint8Array, chunkSize: number): Uint8Array[] {
    let index = 0;
    const arrayLength = input.length;
    const chunks = [];

    while (index < arrayLength) {
      chunks.push(input.slice(index, index + chunkSize));
      index += chunkSize;
    }

    return chunks;
  }
}
