import {
  IAddJobProps,
  SyncCollection,
  SyncQueueJobMethod,
} from './sync-queue.store';

export const getCollections = (job: IAddJobProps): Array<SyncCollection> => {
  switch (job.method) {
    case SyncQueueJobMethod.PULL:
      return [];
    case SyncQueueJobMethod.PUSH:
      return job.collections;
    case SyncQueueJobMethod.SINGLE_PULL:
      return [{ collection: '', collectionName: job.collectionName }];
    default:
      throw new Error('Invalid Method');
  }
};
