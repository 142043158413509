import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type EducationDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  educationalInstitutionName?: string;
  number?: string;
  startDate?: number;
  endDate?: number;
  obtainingDate?: number;
  scan?: string;
  attachments?: Array<string>;
  storageLocation?: string;
  comments?: string;
};

export type EducationDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  educationalInstitutionName?: QuestionType.TEXT;
  number?: QuestionType.TEXT;
  startDate?: QuestionType.DATE_TIME;
  endDate?: QuestionType.DATE_TIME;
  obtainingDate?: QuestionType.DATE_TIME;
  scan?: QuestionType.FILE_UPLOAD;
  attachments?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type EducationFormType = TDtoWrapper<EducationDtoType, EducationDtoHelpType>;

export const EducationForm: EducationFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  educationalInstitutionName: {
    type: QuestionType.TEXT,
    label: 'Educational institution name',
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  startDate: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  obtainingDate: {
    type: QuestionType.DATE_TIME,
    label: 'Obtaining date',
  },
  attachments: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
    isMultiple: true,
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
