import {
  EncryptionType,
  PrivateKey,
} from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonCollection } from '@/shared/lib/secure-json/core/secure-json-collection';

import { DecryptedData } from './secure-json-collection/types';
import { SecureJsonEncryptor } from './secure-json-encryptor';
import { SecureJsonSerializer } from './secure-json-serializer';

export class SecureJsonBase<T = DecryptedData> extends SecureJsonCollection<T> {
  private encryptor: SecureJsonEncryptor;
  private serializer: SecureJsonSerializer;

  constructor(
    private readonly privateKey: PrivateKey,
    encryptionType: EncryptionType,
  ) {
    super(encryptionType);

    this.encryptor = new SecureJsonEncryptor(encryptionType);
    this.serializer = new SecureJsonSerializer();
  }

  public toString(): string {
    const encryptedCollection = this.encryptor.encrypt(this);
    return this.serializer.toString(encryptedCollection);
  }

  public async fromString(serializedCollection: string) {
    const encryptedCollection =
      this.serializer.fromString(serializedCollection);

    const decryptedCollection = await this.encryptor.decrypt(
      encryptedCollection,
      this.privateKey,
    );

    this.items = decryptedCollection.items;
    return this;
  }

  public async fromStringAsync(serializedCollection: string): Promise<this> {
    const encryptedCollection =
      this.serializer.fromString(serializedCollection);

    const decryptedCollection = await this.encryptor.decryptAsync(
      encryptedCollection,
      this.privateKey,
    );

    this.items = decryptedCollection.items;
    return this;
  }
}
