import { AccountStatus } from '@/shared/generated/sdk';
import { BeneficiaryGroup } from '@/shared/lib/sj-orm/models/beneficiary.dto';

export type NextOwnerUser = {
  id?: string;
  centralDbProfileId?: number;
  code?: string;
  createdAt?: Date;
  email?: string;
  group?: BeneficiaryGroup;
  invitationCode?: string;
  invitationLastSentTime?: Date | null;
  isNew?: boolean;
  isTest?: boolean;
  label?: string;
  name?: string;
  nickName?: string;
  status?: AccountStatus;
  value?: string;
};

export type ModalInviteUser = {
  id?: string;
  isOpen: boolean;
  email?: string;
  code?: string;
  isFirstInvite?: boolean;
};

export type DescriptionInfo = {
  title: string;
  text: string;
};

export type NextOwnerTabs = 'all' | 'registered' | 'invited' | 'notInvited';

export const isNextOwnerTab = (value: string): value is NextOwnerTabs => {
  return ['all', 'registered', 'invited', 'notInvited'].includes(value);
};

export const getLocalStorageKey = (email: string) => `${email}-invited`;
export type SetInviteUser = (value: ModalInviteUser) => void;

type SetInvitedUserState = (user: Array<string>) => Array<string>;
export type SetInvitedUsers = (
  users: Array<string> | SetInvitedUserState,
) => void;

export type SetUserEmails = (
  value: Array<ModalInviteUser['email']>,
) => void | Array<ModalInviteUser['email']>;
