import { Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body::-webkit-scrollbar {
    display: none !important;
  }

  .ant-drawer-body {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  .ant-drawer-footer {
    padding: 24px !important;
    border-top: unset !important;
  }
`;
