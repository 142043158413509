import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BasisOfObtainingType {
  openingACompany = 'openingACompany',
  employment = 'employment',
  studies = 'studies',
  marriage = 'marriage',
  buyingRealEstate = 'buyingRealEstate',
  medicalTreatment = 'medicalTreatment',
  familyReunification = 'familyReunification',
}

const BasisOfObtainingList = [
  {
    label: 'Opening a company',
    key: BasisOfObtainingType.openingACompany,
  },
  {
    label: 'Employment',
    key: BasisOfObtainingType.employment,
  },
  {
    label: 'Studies',
    key: BasisOfObtainingType.studies,
  },
  {
    label: 'Marriage',
    key: BasisOfObtainingType.marriage,
  },
  {
    label: 'Buying real estate',
    key: BasisOfObtainingType.buyingRealEstate,
  },
  {
    label: 'Medical treatment',
    key: BasisOfObtainingType.medicalTreatment,
  },
  {
    label: 'Family reunification',
    key: BasisOfObtainingType.familyReunification,
  },
];

export type ResidencePermitDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  citizenship?: string;
  number?: string;
  cardAccessNumber?: string;
  dateOfIssue?: number;
  isPermanent?: boolean;
  endDate?: number;
  basisOfObtaining?: BasisOfObtainingType;
  limitations?: string;
  other?: string;
  scan?: string;
  attachments?: Array<string>;
  storageLocation?: string; //гибридная ссылка
  comments?: string;
};

export type ResidencePermitDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  citizenship?: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  cardAccessNumber?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  isPermanent?: QuestionType.CHECKBOX;
  endDate?: QuestionType.DATE_TIME;
  basisOfObtaining?: QuestionType.SELECT_FROM_LIST;
  limitations?: QuestionType.TEXT;
  other?: QuestionType.TEXT;
  scan?: QuestionType.FILE_UPLOAD;
  attachments?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT; //гибридная ссылка
  comments?: QuestionType.TEXT_MULTILINE;
};

type ResidencePermitFormType = TDtoWrapper<
  ResidencePermitDtoType,
  ResidencePermitDtoHelpType
>;

export const ResidencePermitForm: ResidencePermitFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*Name(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Surname(s)',
    placeholder: 'Fill in as in the document',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  citizenship: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Citizenship',
    items: CountriesList,
    withSearch: true,
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  cardAccessNumber: {
    type: QuestionType.TEXT,
    label: 'Card access number',
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  isPermanent: {
    type: QuestionType.CHECKBOX,
    label: 'Permanent residence?',
  },
  basisOfObtaining: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Grounds for obtaining',
    items: BasisOfObtainingList,
  },
  limitations: {
    type: QuestionType.TEXT,
    label: 'Limitations',
  },
  other: {
    type: QuestionType.TEXT,
    label: 'Other data',
  },
  attachments: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Documents scan',
    isMultiple: true,
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
