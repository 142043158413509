import { type FC } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, DropdownProps, Flex } from 'antd';
import styled from 'styled-components';

type ActionDropdownProps = {
  menu: DropdownProps['menu'];
};

export const ActionDropdown: FC<ActionDropdownProps> = ({ menu }) => {
  return (
    <Dropdown menu={menu} trigger={['click']}>
      <StyledFlex justify="center" align="center">
        <MoreOutlined onClick={(e) => e.preventDefault()} />
      </StyledFlex>
    </Dropdown>
  );
};

const StyledFlex = styled(Flex)`
  cursor: 'pointer';
  height: '100%';
`;
