import { v4 as uuidv4 } from 'uuid';

import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName, ICollections } from '@/shared/lib/sj-orm/constants';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { AssistantMnemonicDto } from '@/shared/lib/sj-orm/models/assistant-mnemonic.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { AssetInformationDocumentDto } from '@/shared/lib/sj-orm/models/document/asset-information.document.dto';
import { BeneficiaryPersonalDataDocumentDto } from '@/shared/lib/sj-orm/models/document/beneficiary-personal-data.document.dto';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { EncryptedFileKeyDto } from '@/shared/lib/sj-orm/models/encrypted-file-key.dto';
import { FileMetaInfoDto } from '@/shared/lib/sj-orm/models/file-meta-info.dto';
import { MigrationApplyResultDto } from '@/shared/lib/sj-orm/models/migration-apply-result.dto';
import { MyHubDto } from '@/shared/lib/sj-orm/models/my-hub.dto';
import { NotificationDto } from '@/shared/lib/sj-orm/models/notification.dto';
import { SoweAttachmentDto } from '@/shared/lib/sj-orm/models/sowe/sowe-attachment.dto';
import { SJCollection } from '@/shared/lib/sj-orm/sj-collection';
import { ISecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';

import { DelegatingRequestDto } from '../models/delegating-request.dto';
import { ScenarioMetaInfoDto } from '../models/scenario-meta-info.dto';
import { SoweDto } from '../models/sowe/sowe.dto';

export class SJDatabase {
  collections: ICollections = {
    assets: undefined,
    sowe: undefined,
    soweAttachments: undefined,
    encryptedFileKey: undefined,
    beneficiaries: undefined,
    delegatingRequests: undefined,
    contacts: undefined,
    // passportDocuments: undefined,
    // unknownDocuments: undefined,
    beneficiaryPersonalDataDocuments: undefined,
    assetInformationDocument: undefined,
    // soweAttachedPersonalIdentifier: undefined,
    assistantMnemonic: undefined,
    notification: undefined,
    privateDocuments: undefined,
    personalIdentifiersDocuments: undefined,
    fileMetaInfos: undefined,
    scenarioMetaInfos: undefined,
    myHub: undefined,
    migrations: undefined,
  };

  get size() {
    const allItems = Object.entries(this.collections)
      .filter(([key]) => key !== CollectionName.MIGRATIONS)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value)
      .flat()
      .filter(Boolean);

    return allItems.reduce((acc, cur) => acc + cur.size(), 0);
  }

  // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
  constructor(readonly store: ISecureJsonCollectionsStore) {
    const assetCollection = store.collections.get(CollectionName.ASSETS);
    const myHubCollection = store.collections.get(CollectionName.MY_HUB);

    const encryptedFileKeyCollection = store.collections.get(
      CollectionName.ENCRYPTED_FILE_KEY,
    );
    const beneficiariesCollection = store.collections.get(
      CollectionName.BENEFICIARIES,
    );
    const delegatingRequestsCollection = store.collections.get(
      CollectionName.DELEGATING_REQUESTS,
    );
    const beneficiaryPersonalDataDocumentsCollection = store.collections.get(
      CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
    );
    const assetInformationDocumentCollection = store.collections.get(
      CollectionName.ASSET_INFORMATION_DOCUMENT,
    );
    const sowe = store.collections.get(CollectionName.SOWE);
    const soweAttachmentCollection = store.collections.get(
      CollectionName.SOWE_ATTACHMENTS,
    );
    const assistantMnemonicCollection = store.collections.get(
      CollectionName.ASSISTANT_MNEMONIC,
    );
    const contactsCollection = store.collections.get(CollectionName.CONTACTS);
    const privateDocumentsCollection = store.collections.get(
      CollectionName.PRIVATE_DOCUMENTS,
    );
    const personalIdentifiersDocumentsCollection = store.collections.get(
      CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
    );
    const fileMetaInfosCollection = store.collections.get(
      CollectionName.FILE_META_INFO,
    );
    const notificationCollection = store.collections.get(
      CollectionName.NOTIFICATION,
    );
    const scenarioMetaInfosCollection = store.collections.get(
      CollectionName.SCENARIO_META_INFO,
    );

    if (assetCollection) {
      this.collections.assets = new SJCollection<AssetDto>(
        CollectionName.ASSETS,
        assetCollection,
        store.setCollection,
      );
    }

    if (myHubCollection) {
      this.collections.myHub = new SJCollection<MyHubDto>(
        CollectionName.MY_HUB,
        myHubCollection,
        store.setCollection,
      );
    }

    if (sowe) {
      this.collections.sowe = new SJCollection<SoweDto>(
        CollectionName.SOWE,
        sowe,
        store.setCollection,
      );
    }

    if (soweAttachmentCollection) {
      this.collections.soweAttachments = new SJCollection<SoweAttachmentDto>(
        CollectionName.SOWE_ATTACHMENTS,
        soweAttachmentCollection,
        store.setCollection,
      );
    }

    if (encryptedFileKeyCollection) {
      this.collections.encryptedFileKey = new SJCollection<EncryptedFileKeyDto>(
        CollectionName.ENCRYPTED_FILE_KEY,
        encryptedFileKeyCollection,
        store.setCollection,
      );
    }
    if (beneficiariesCollection) {
      this.collections.beneficiaries = new SJCollection<BeneficiaryDto>(
        CollectionName.BENEFICIARIES,
        beneficiariesCollection,
        store.setCollection,
      );
    }
    if (delegatingRequestsCollection) {
      this.collections.delegatingRequests =
        new SJCollection<DelegatingRequestDto>(
          CollectionName.DELEGATING_REQUESTS,
          delegatingRequestsCollection,
          store.setCollection,
        );
    }
    if (beneficiaryPersonalDataDocumentsCollection) {
      this.collections.beneficiaryPersonalDataDocuments =
        new SJCollection<BeneficiaryPersonalDataDocumentDto>(
          CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
          beneficiaryPersonalDataDocumentsCollection,
          store.setCollection,
        );
    }
    if (assetInformationDocumentCollection) {
      this.collections.assetInformationDocument =
        new SJCollection<AssetInformationDocumentDto>(
          CollectionName.ASSET_INFORMATION_DOCUMENT,
          assetInformationDocumentCollection,
          store.setCollection,
        );
    }
    if (assistantMnemonicCollection) {
      this.collections.assistantMnemonic =
        new SJCollection<AssistantMnemonicDto>(
          CollectionName.ASSISTANT_MNEMONIC,
          assistantMnemonicCollection,
          store.setCollection,
        );
    }

    if (notificationCollection) {
      this.collections.notification = new SJCollection<NotificationDto>(
        CollectionName.NOTIFICATION,
        notificationCollection,
        store.setCollection,
      );
    }

    if (contactsCollection) {
      this.collections.contacts = new SJCollection<ContactDto>(
        CollectionName.CONTACTS,
        contactsCollection,
        store.setCollection,
      );
    }
    if (privateDocumentsCollection) {
      this.collections.privateDocuments = new SJCollection<PrivateDocumentDto>(
        CollectionName.PRIVATE_DOCUMENTS,
        privateDocumentsCollection,
        store.setCollection,
      );
    }
    if (personalIdentifiersDocumentsCollection) {
      this.collections.personalIdentifiersDocuments =
        new SJCollection<PersonalIdentifierDocumentDto>(
          CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          personalIdentifiersDocumentsCollection,
          store.setCollection,
        );
    }
    if (fileMetaInfosCollection) {
      this.collections.fileMetaInfos = new SJCollection<FileMetaInfoDto>(
        CollectionName.FILE_META_INFO,
        fileMetaInfosCollection,
        store.setCollection,
      );
    }
    if (scenarioMetaInfosCollection) {
      this.collections.scenarioMetaInfos =
        new SJCollection<ScenarioMetaInfoDto>(
          CollectionName.SCENARIO_META_INFO,
          scenarioMetaInfosCollection,
          store.setCollection,
        );
    }
    const userMigrationsCollection = store.collections.get(
      CollectionName.MIGRATIONS,
    ) as SecureJsonBase<MigrationApplyResultDto> | undefined;

    if (userMigrationsCollection) {
      this.collections.migrations = new SJCollection<MigrationApplyResultDto>(
        CollectionName.MIGRATIONS,
        userMigrationsCollection,
        store.setCollection,
      );
    }
  }

  public generateId(prefix: CollectionName): string {
    return `${prefix || 'id'}-${uuidv4()}`;
  }

  static generateId(prefix: CollectionName): string {
    return `${prefix || 'id'}-${uuidv4()}`;
  }
}
