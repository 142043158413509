import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import {
  ActivityList,
  ActivityType,
  AssetCategory,
  HighMediumLowList,
  HighMediumLowType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';

export enum IndividualContactPhoneNumberType {
  MOBILE = 'mobile',
  WORK = 'work',
  HOME = 'home',
}

const IndividualContactPhoneNumberTypesList = [
  {
    label: 'forms_contacts:individual.enums.phoneType.mobile',
    key: IndividualContactPhoneNumberType.MOBILE,
  },
  {
    label: 'forms_contacts:individual.enums.phoneType.work',
    key: IndividualContactPhoneNumberType.WORK,
  },
  {
    label: 'forms_contacts:individual.enums.phoneType.home',
    key: IndividualContactPhoneNumberType.HOME,
  },
];

export enum IndividualContactEmailType {
  PERSONAL = 'personal',
  WORK = 'work',
}

const IndividualContactEmailTypesList = [
  {
    label: 'forms_contacts:individual.enums.emailType.personal',
    key: IndividualContactEmailType.PERSONAL,
  },
  {
    label: 'forms_contacts:individual.enums.emailType.work',
    key: IndividualContactEmailType.WORK,
  },
];

export enum IndividualContactAddressType {
  PERSONAL = 'personal',
  WORK = 'work',
  HOME = 'home',
  OTHER = 'other',
}

const IndividualContactAddressTypesList = [
  {
    label: 'forms_contacts:individual.enums.addressType.personal',
    key: IndividualContactAddressType.PERSONAL,
  },
  {
    label: 'forms_contacts:individual.enums.addressType.work',
    key: IndividualContactAddressType.WORK,
  },
  {
    label: 'forms_contacts:individual.enums.addressType.home',
    key: IndividualContactAddressType.HOME,
  },
  {
    label: 'forms_contacts:individual.enums.addressType.other',
    key: IndividualContactAddressType.OTHER,
  },
];

export enum IndividualContactType {
  PERSONAL = 'personal',
  FINANCES = 'finances',
  BUSINESS = 'business',
  FAMILY = 'family',
  SOS = 'sos',
}

const IndividualContactTypesList = [
  {
    label: 'forms_contacts:individual.enums.contactType.personal',
    key: IndividualContactType.PERSONAL,
  },
  {
    label: 'forms_contacts:individual.enums.contactType.finances',
    key: IndividualContactType.FINANCES,
  },
  {
    label: 'forms_contacts:individual.enums.contactType.business',
    key: IndividualContactType.BUSINESS,
  },
  {
    label: 'forms_contacts:individual.enums.contactType.family',
    key: IndividualContactType.FAMILY,
  },
  {
    label: 'forms_contacts:individual.enums.contactType.sos',
    key: IndividualContactType.SOS,
  },
];

export enum IndividualContactRoleType {
  AssetProxy = 'asset_proxy',
  AssetManager = 'asset_manager',
  Service = 'service',
  Manager = 'manager',
  Advisor = 'advisor',
  Partner = 'partner',
  RegisteredOwner = 'registeredOwner',
  Other = 'other',
}

const IndividualContactRoleTypesList = [
  {
    label: 'forms_contacts:individual.enums.contactRole.asset_proxy',
    key: IndividualContactRoleType.AssetProxy,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.asset_manager',
    key: IndividualContactRoleType.AssetManager,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.service',
    key: IndividualContactRoleType.Service,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.manager',
    key: IndividualContactRoleType.Manager,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.advisor',
    key: IndividualContactRoleType.Advisor,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.partner',
    key: IndividualContactRoleType.Partner,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.registeredOwner',
    key: IndividualContactRoleType.RegisteredOwner,
  },
  {
    label: 'forms_contacts:individual.enums.contactRole.other',
    key: IndividualContactRoleType.Other,
  },
];

export enum IndividualContactAccessToAssetType {
  Maximum = 'maximum',
  Partial = 'partial',
  Information = 'information',
}

const IndividualContactAccessToAssetTypesList = [
  {
    label: 'forms_contacts:individual.enums.assetAccessType.maximum',
    key: IndividualContactAccessToAssetType.Maximum,
  },
  {
    label: 'forms_contacts:individual.enums.assetAccessType.partial',
    key: IndividualContactAccessToAssetType.Partial,
  },
  {
    label: 'forms_contacts:individual.enums.assetAccessType.information',
    key: IndividualContactAccessToAssetType.Information,
  },
];

export enum IndividualContactAccessGroundsType {
  Authority = 'authority',
  Agreement = 'agreement',
  PowerOfAttorney = 'power_of_attorney',
  Other = 'other',
}

const IndividualContactAccessGroundsTypesList = [
  {
    label: 'forms_contacts:individual.enums.accessGrounds.authority',
    key: IndividualContactAccessGroundsType.Authority,
  },
  {
    label: 'forms_contacts:individual.enums.accessGrounds.agreement',
    key: IndividualContactAccessGroundsType.Agreement,
  },
  {
    label: 'forms_contacts:individual.enums.accessGrounds.power_of_attorney',
    key: IndividualContactAccessGroundsType.PowerOfAttorney,
  },
  {
    label: 'forms_contacts:individual.enums.accessGrounds.other',
    key: IndividualContactAccessGroundsType.Other,
  },
];

export enum IndividualContactUrgencyType {
  Urgent = 'urgent',
  Current = 'current',
}

const IndividualContactUrgencyTypesList = [
  {
    label: 'forms_contacts:individual.enums.urgencyType.urgent',
    key: IndividualContactUrgencyType.Urgent,
  },
  {
    label: 'forms_contacts:individual.enums.urgencyType.current',
    key: IndividualContactUrgencyType.Current,
  },
];

type IndividualContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  nickname: string;
  selectedCountry: string;
  phoneNumberType?: IndividualContactPhoneNumberType;
  phone?: number;
  emailType?: IndividualContactEmailType;
  email?: string;
  addressType?: IndividualContactAddressType;
  address?: string;
  contactType?: IndividualContactType;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  secondName?: string;
  activityType?: ActivityType;
  trustLevel?: HighMediumLowType;
  organization?: string;
  jobTitle?: string;
  associatedWithAnAsset?: boolean;
  assetId?: DtoId;
  assetCommentary?: string;
  role?: IndividualContactRoleType;
  accessType?: IndividualContactAddressType;
  powers?: DtoId[];
  urgencyType?: IndividualContactUrgencyType;
  accessGrounds?: IndividualContactAccessGroundsType;
  commentary?: string;
  businessCard?: string;
  photo?: string;
};

type IndividualContactHelpPartType = {
  nickname: QuestionType.TEXT;
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  phoneNumberType?: QuestionType.SELECT_FROM_LIST;
  phone?: QuestionType.PHONE_NUMBER;
  emailType?: QuestionType.SELECT_FROM_LIST;
  email?: QuestionType.EMAIL;
  addressType?: QuestionType.SELECT_FROM_LIST;
  address?: QuestionType.TEXT;
  contactType?: QuestionType.SELECT_FROM_LIST;
  fullName?: QuestionType.TEXT;
  firstName?: QuestionType.TEXT;
  lastName?: QuestionType.TEXT;
  secondName?: QuestionType.TEXT;
  activityType?: QuestionType.SELECT_FROM_LIST;
  trustLevel?: QuestionType.SELECT_FROM_LIST;
  organization?: QuestionType.TEXT;
  jobTitle?: QuestionType.TEXT;
  // asset relation
  associatedWithAnAsset?: QuestionType.CHECKBOX;
  assetId?: QuestionType.PICK_ARRAY_OF_DTO;
  assetCommentary?: QuestionType.TEXT;
  role?: QuestionType.SELECT_FROM_LIST;
  accessType?: QuestionType.SELECT_FROM_LIST;
  powers?: QuestionType.PICK_ARRAY_OF_DTO;
  urgencyType?: QuestionType.SELECT_FROM_LIST;
  accessGrounds?: QuestionType.SELECT_FROM_LIST;
  commentary?: QuestionType.TEXT_MULTILINE;
  photo?: QuestionType.FILE_UPLOAD;
  businessCard?: QuestionType.FILE_UPLOAD;
};

type IndividualContactFormType = TDtoWrapper<
  IndividualContactDataPartType,
  IndividualContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const IndividualContactForm: IndividualContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.nickname.label',
    placeholder: 'Enter Nickname e.g., Wife, Son',
    tooltip: 'forms_contacts:individual.inputs.nickname.tooltip',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  firstName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.firstName.label',
    placeholder: 'Enter First name',
  },
  lastName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.lastName.label',
    placeholder: 'Enter Last name',
  },
  secondName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.secondName.label',
    placeholder: 'Enter Second name',
  },
  phoneNumberType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.phoneNumberType.label',
    items: IndividualContactPhoneNumberTypesList,
  },
  phone: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_contacts:individual.inputs.phoneNumber.label',
  },
  emailType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.emailType.label',
    items: IndividualContactEmailTypesList,
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_contacts:individual.inputs.email.label',
  },
  addressType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.addressType.label',
    items: IndividualContactAddressTypesList,
  },
  address: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.address.label',
  },
  contactType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.contactType.label',
    items: IndividualContactTypesList,
  },
  activityType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.activityType.label',
    items: ActivityList,
  },
  trustLevel: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.trustLevel.label',
    items: HighMediumLowList,
  },
  organization: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.organization.label',
  },
  jobTitle: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.jobTitle.label',
  },
  associatedWithAnAsset: {
    type: QuestionType.CHECKBOX,
    label: 'forms_contacts:individual.inputs.associatedWithAnAsset.label',
  },
  assetId: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:individual.inputs.assetId.label',
    items: 'assets',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
    dtoType: AssetCategory.BANK_CELLS,
  },
  assetCommentary: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:individual.inputs.assetCommentary.label',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  role: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.role.label',
    items: IndividualContactRoleTypesList,
  },
  accessType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.accessType.label',
    items: IndividualContactAccessToAssetTypesList,
  },
  powers: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:individual.inputs.powers.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  urgencyType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.urgencyType.label',
    items: IndividualContactUrgencyTypesList,
  },
  accessGrounds: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.accessGrounds.label',
    items: IndividualContactAccessGroundsTypesList,
  },
  commentary: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:individual.inputs.contactComment.label',
  },
  photo: {
    type: QuestionType.FILE_UPLOAD,
    label: 'forms_contacts:individual.inputs.photo.label',
  },
  businessCard: {
    type: QuestionType.FILE_UPLOAD,
    label: 'forms_contacts:individual.inputs.businessCard.label',
  },
};

export { IndividualContactForm };
